










import { Component, Prop, Vue } from 'vue-property-decorator';
import GalleryWork from '@/models/GalleryWork';


@Component
export default class GalleryItemInfo extends Vue {
  @Prop({ required: true }) public work!: GalleryWork;
  @Prop({ type: Boolean, default: true }) public school!: boolean;
}
