








import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import GalleryWork from '@/models/GalleryWork';
import GalleryItemInfo from '@/components/GalleryItemInfo.vue';


@Component({
  components: {
    GalleryItemInfo,
  },
})
export default class GalleryItem extends Vue {
  @Prop({ required: true }) public work!: GalleryWork;
  @Prop({ type: Boolean, default: true }) public school!: boolean;

  public full = false;

  @Emit()
  public openFull() {
    return this.work;
  }
}
