export default {
  StartPage: 'StartPage',
  Partners: 'Partners',
  Rules: 'Rules',
  License: 'License',
  About: 'About',
  Register: 'Register',
  Forgot: 'Forgot',
  Jury: 'Jury',
  Confidential: 'Confidential',
  Gallery: 'Gallery',
  OtherGallery: 'OtherGallery',
  Contest2020: 'Contest2020',
};
