



































































import { Component, Prop } from 'vue-property-decorator';
import AnimaButton from '@/components/AnimaButton.vue';
import VueRecaptcha from 'vue-recaptcha';
import VueI18n from "vue-i18n";
import { i18n } from "@/services/i18n";
import TranslateResult = VueI18n.TranslateResult;
import AnimaVuetifyElement from "@/components/AnimaVuetifyElement.vue";

const selectFormatter = (item: any) => item.text ?? item;

@Component({
  components: {
    AnimaButton,
    VueRecaptcha,
  },
})
export default class AnimaCombobox extends AnimaVuetifyElement {
  @Prop({ required: true, default: () => [] }) public items!: any[];
  @Prop({ default: () => [] }) public restrictedItems!: any[];
  @Prop({ default: () => i18n.t('Combobox.createNew') }) public creationText!: TranslateResult;
  @Prop({ default: () => i18n.t('Combobox.noDataAvailable') }) public noDataText!: TranslateResult;
  @Prop({ type: Boolean, default: true }) public hideSelected!: boolean;
  @Prop({ type: Boolean, default: true }) public autoSelectFirst!: boolean;
  @Prop({ type: Boolean, default: true }) public allowCreate!: boolean;
  @Prop({ type: Boolean, default: false }) public multiple!: boolean;
  @Prop({ type: Boolean, default: false }) public outlined!: boolean;
  @Prop({ type: Boolean, default: false }) public dense!: boolean;
  @Prop({ type: Boolean, default: false }) public openOnClear!: boolean;
  @Prop({ default: () => selectFormatter }) public selectFormatter!: (item: any) => any;

  public search = '';

  get model() {
    if (this.multiple && !Array.isArray(this.value)) {
      return [this.value];
    }
    return this.value;
  }

  set model(value: any) {
    if (value === null || value === undefined) {
      value = this.multiple ? [] : '';
    }
    this.search = '';
    if (this.multiple) {
      const primitiveValues = value.map((item: string | number | { text: string; value: string }) => {
        return typeof item === 'string' || typeof item === 'number' ? item : item.value;
      });
      if (!this.allowCreate) {
        const possibleValues = this.items.map((item) => item.value ?? item);
        const withoutCreatedValues = primitiveValues.filter((item: string) => possibleValues.includes(item));
        this.input(withoutCreatedValues);
        return;
      }
      this.input(primitiveValues);
      return;
    }

    if (value.value) {
      this.input(value);
    } else {
      this.input({ value, text: value });
    }
  }

  get trimmedSearch(): string {
    return this.search ? this.search.trim() : this.search;
  }

  public add() {
    if (!this.trimmedSearch) {
      return;
    }
    this.model = this.multiple ? [...this.model, this.trimmedSearch] : this.trimmedSearch;
    if (!this.multiple) {
      // @ts-expect-error
      this.$refs.combobox.blur();
    }
  }

  public remove(itemToDelete: string) {
    this.model = this.model.filter((item: string) => item !== itemToDelete);
  }
}
