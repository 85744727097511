



















































import _ from 'lodash';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import AnimaButton from '@/components/AnimaButton.vue';
import AnimaInput from '@/components/AnimaInput.vue';
import RegisteredWork from '@/models/RegisteredWork';
import WorkForm from '@/components/forms/WorkForm.vue';
import Work from '@/models/Work';

@Component({
  components: {
    AnimaButton,
    AnimaInput,
    WorkForm,
  },
})
export default class WorkPreview extends Vue {
  @Prop({ required: true }) public work!: RegisteredWork;
  @Prop({ type: Boolean, default: true }) public divided!: boolean;

  public form = {};

  public edit = false;
  public loading = false;

  @Emit()
  public deleted() {
    return this.work;
  }

  public toggleEdit() {
    this.edit = !this.edit;
  }

  get vform(): HTMLFormElement {
    const localRef = this.$refs.forms as Vue
    return localRef.$refs.workForm as HTMLFormElement;
  }

  public saveWork() {

    if(this.vform.validate()) {
      this.loading = true;
      const work = new Work(this.form);

      work.getParams().then((workParams) => {
        const params = {
          id: this.work.id,
          ...workParams,
        }
        this.$store.dispatch('editWork', params).then(() => {
          this.$store.dispatch('downloadWorks').then(() => {
            this.toggleEdit();
          });
        }).catch((error) => {
          this.$notify({
            type: 'error',
            title: String(this.$t('Errors.unexpected')),
            message: '',
            duration: 0,
          });
        }).finally(() => {
          this.loading = false;
        })
      }).catch(() => {
        this.loading = false;
      });
    }
  }

  public deleteWork() {
    this.loading = true;
    this.$store.dispatch('deleteWork', this.work.id).catch(() => {
      this.$notify({
        type: 'error',
        title: String(this.$t('Errors.unexpected')),
        message: '',
        duration: 0,
      });
    }).finally(() => {
      this.loading = false;
    });
  }

  public created() {
    this.form = _.cloneDeep(this.work);
  }
}
