import http from './http';
import Work from '@/models/Work';
import RegisteredWork, { RegisteredWorkParams } from '@/models/RegisteredWork';
import { OrientationsType, WorkTypes } from '@/constants';
import Author from '@/models/Author';

// Авторизация
// /login POST
//
//   login
// password
//
// 1001 - поле не заполнено (это может не понадобиться тебе, я думаю у тебя и так своя валидация будет на фронте)
// 1002 - неверный E-mail (не прошёл маску)
// 1005 - неверная пара логин-пароль


// Регистрация
// /signup POST
//
//   school
// address
// gender
// fullName
// position
// email
// phone
//
// 1001 - поле не заполнено (это может не понадобиться тебе, я думаю у тебя и так своя валидация будет на фронте)
// 1002 - неверный E-mail (не прошёл маску)
// 1003 - юзер с таким Email уже есть
// 1004 - юзер с таким телефоном уже есть

export interface RegisterCredentialsBase {
  country: string;
  school: string;
  address: string;
  gender: string;
  fullName: string;
  position: string;
  email: string;
  phone: number;
  language: string;
}

export interface RegisterCredentials extends RegisterCredentialsBase {
  city: string;
}

export interface HumanName {
  firstName: string;
  lastName: string;
  middleName?: string;
}

export interface LoginCredentials {
  login: string;
  password: string;
}

export interface UploadItem {
  title: string;
  description: string;
  authors: Author[];
  creationPlace?: string; // deprecated
  orientation?: OrientationsType;
  image?: File;
  img?: string;
  material?: string;
  width?: number;
  height?: number;
}

export default class UserApi {
  /**
   * Сброс пароля
   */
  public static getForgotPassword(email: string): Promise<any> {
    return http.get('user/reset', { email }).then(({ data }) => data);
  }

  /** Регистрация нового пользователя */
  public static postRegister(params: RegisterCredentials, token?: string) {
    return http.post('signup' + (token ? '/' + token : ''), params).then(({ data }) => data);
  }

  public static postLogin(params: LoginCredentials) {
    return http.post('login', params).then(({ data }) => data);
  }

  public static postUploadWorks(params: Work[]) {
    return http.post('item ', params, true).then(({ data }) => data);
  }

  public static getWorks(): Promise<RegisteredWorkParams[]> {
    return http.post('getItems ', {}, true).then(({ data }) => data.data);
  }

  public static postEditWork(params: RegisteredWork) {
    return http.post('editItem ', params, true).then(({ data }) => data);
  }

  public static postDeleteWork(params: { id: number }) {
    return http.post('deleteItem', params, true).then(({ data }) => data);
  }

  public static getAllWorks(type?: WorkTypes) {
    return http.get(`allItems?type=${type ?? WorkTypes.all}`).then(({ data }) => data.data);
  }
}
