import Author from '@/models/Author';
import { OrientationsType } from '@/constants';

export default class Work {
  public title: string;
  public description: string;
  public authors: Author[];
  public creationPlace?: string; // deprecated
  public image: Blob;
  public orientation: OrientationsType;
  public img?: string; // URL
  public material?: string;
  public width?: number;
  public height?: number;

  constructor(props: any) {
    this.title = props.title;
    this.description = props.description;
    this.authors = props.authors;
    this.creationPlace = props.creationPlace;
    this.image = props.image;
    this.img = props.img;
    this.material = props.material;
    this.orientation = props.orientation;
    this.width = props.width;
    this.height = props.height;
  }

  public getParams(): Promise<{ [key: string]: any }> {
    return new Promise((resolve, reject) => {

      const params: { [key: string]: any } = {
        title: this.title,
        description: this.description,
        authors: this.authors,
        creationPlace: this.creationPlace,
        material: this.material,
        orientation: this.orientation,
        width: this.width,
        height: this.height,
      };

      if (this.img) {
        params.image = this.img;
        resolve(params);
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        if (e && e.target && e.target.result) {
          params.image = e.target.result;
          resolve(params);
        }
      };
      reader.onerror = function (error) {
        reject(error);
      };
      reader.readAsDataURL(this.image);
    });
  }
}
