export interface AuthorInterface {
  firstName?: string;
  lastName?: string;
  age?: number;
}

export default class Author {
  public firstName: string;
  public lastName: string;
  public age: number;

  constructor(props: AuthorInterface) {
    this.firstName = props.firstName ?? '';
    this.lastName = props.lastName ?? '';
    this.age = props.age ?? 15;
  }
}
