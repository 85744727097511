












import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Language extends Vue {
  get langs() {
    return {
      ru: { value: 'ru', label: 'Ru' },
      it: { value: 'it', label: 'It' },
    }
  }
  get language() {
    return this.$route.params.language || 'ru';
  }

  public selectLanguage(language: string) {
    if (this.$i18n.locale !== language) {
      this.$i18n.locale = language;
    }
    this.$router.replace({ params: Object.assign({}, this.$route.params, { language }) });
    // this.$store.commit('setCurrentLanguage', language);
  }
}
