



























import { Component, Prop, Vue } from 'vue-property-decorator';
import Language from "@/components/Language.vue";
import LogoSmall from "@/components/LogoSmall.vue";
import RouterNames from '@/router/RouterNames';

@Component({
  components: {
    Language,
    LogoSmall,
  },
})
export default class Footer extends Vue {
  @Prop({ type: Boolean, default: false }) public short!: boolean;

  public RouterNames = RouterNames;

  get language() {
    return this.$route.params.language || 'ru';
  }
}
