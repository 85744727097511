export const confidentialRu = `
<p class="medium">ПОЛИТИКА В ОТНОШЕНИИ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</p>

<p>Настоящая Политика в отношении обработки персональных данных (далее — Политика) распространяется на всю информацию, которую АНО «АНИМА» и/или лица, входящие в одну группу с АНО «АНИМА», ее партнеры могут получить о субъекте персональных данных (далее также — Пользователь) во время использования последним любого из продуктов, программ, сайтов, сервисов, служб, товаров, работ или услуг АНО «АНИМА» и/или лиц, входящих в одну группу с АНО «АНИМА», ее партнеров поставки товаров, оказания услуг/выполнения работ Пользователем АНО «АНИМА» и/или лицам, входящим в одну группу с АНО «АНИМА», ее партнерам (далее — Сервисы) и в ходе исполнения АНО «АНИМА» любых соглашений и договоров с Пользователем.

<p>Использование Пользователем Сервисов АНО «АНИМА» означает согласие Пользователя с настоящей Политикой и указанными в ней условиями обработки его персональных данных, в случае несогласия с этими условиями Пользователь должен воздержаться от использования Сервисов.
<p>Согласие Пользователя с Политикой, выраженное им в рамках отношений с одним из лиц, входящих в одну группу с АНО «АНИМА», распространяется на все остальные лица группы, партнеров.

<p class="medium">1. ОБЩИЕ ПОЛОЖЕНИЯ

<p>1.1. Назначением настоящей Политики является обеспечение надлежащей защиты информации о Пользователе, в т.ч. его персональных данных, от несанкционированного доступа и разглашения.

<p>1.2. Основные понятия, применяемые в настоящей Политике, используются в значениях, как они определены Федеральным законом от 27.07.2006 N 152-ФЗ «О персональных данных», если иное не установлено положениями Политики.

<p>1.3. С целью выполнения требований действующего законодательства АНО «АНИМА» обеспечивает легитимность обработки и безопасности персональных данных (далее также – ПДн) Пользователя, полученных АНО «АНИМА» в процессе его деятельности.

<p>1.4. Обработка ПДн в АНО «АНИМА» основана на следующих принципах:
<ul>
<li>обработка ПДн осуществляется на законной и справедливой основе;

<li>обработка ПДн ограничивается достижением конкретных, заранее определенных и законных целей; не допускается обработка персональных данных, несовместимая с целями сбора персональных данных;

<li>не допускается объединение баз данных, содержащих ПДн, обработка которых осуществляется в целях, несовместимых между собой;

<li>обработке подлежат только персональные данные, которые отвечают целям их обработки;

<li>содержание и объем обрабатываемых ПДн, способы их обработки соответствуют целям обработки, обрабатываемые ПДн не должны быть избыточными по отношению к заявленным целям их обработки;

<li>при обработке ПДн обеспечиваются точность ПДн, их достаточность, а в необходимых случаях и актуальность по отношению к целям обработки ПДн, принимаются необходимые меры по удалению или уточнению неполных или неточных данных;

<li>хранение ПДн осуществляется в форме, позволяющей определить Пользователя, не дольше, чем этого требуют цели обработки ПДн, если срок хранения ПДн не установлен федеральным законом, договором, стороной которого, выгодоприобретателем или поручителем по которому является субъект персональных данных;

<li>обрабатываемые ПДн уничтожаются либо обезличиваются по достижении целей обработки или в случае утраты необходимости в достижении этих целей, если иное не предусмотрено федеральным законом.
</ul>
</p>
<p>1.5. При раскрытии и обработке ПДн АНО «АНИМА»:
<ul>
<li>обязуется использовать полученную информацию исключительно для целей, указанных в настоящей Политике;

<li>обязуется предоставить Пользователю по его просьбе информацию, касающуюся обработки его персональных данных, предоставить Пользователю возможность ознакомления с его персональными данными;

<li>обязуется принимать необходимые правовые, организационные и технические меры или обеспечивать их принятие для защиты персональных данных от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления, распространения персональных данных, а также от иных неправомерных действий в отношении персональных данных;

<li>несет иные обязанности и имеет права, установленные действующим законодательством.
</ul>
</p>
<p>1.6. При раскрытии и обработке ПДн Пользователь:
<ul>
<li>обязуется предоставить корректную и правдивую информацию о персональных данных, необходимую для пользования Сервисами;

<li>обязуется обновить или дополнить предоставленную информацию о персональных данных в случае изменения данной информации;

<li>имеет право на получение сведений, информации, касающейся обработки его ПДн;

<li>имеет право требовать от АНО «АНИМА» уточнения персональных данных, их блокирования или уничтожения 
в случае, если персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки, 
а также принимать предусмотренные законом меры по защите своих прав;

<li>имеет право обжаловать действия или бездействие АНО «АНИМА»;

<li>несет иные обязанности и имеет права, установленные действующим законодательством.
</ul>
</p>
<p class="medium">2. ЦЕЛИ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ

<p>2.1. В соответствии с принципами обработки ПДн, в АНО «АНИМА» определены цели обработки:
<ul>
<li>предоставление Пользователю возможности использования Сервисов;

<li>верификация Пользователя в целях улучшения качества Сервисов, предоставления ему индивидуального окружения 
и настроек сервиса, предоставления дополнительных возможностей сервисов, увеличение удобства их использования и обеспечения безопасности действий, совершаемых Пользователем, разработка новых Сервисов;

<li>связь с Пользователем, в том числе направление уведомлений, запросов и информации, а также обработка запросов и заявок 
от Пользователя;

<li>проведение акций, конкурсов, игр или иных подобных мероприятий для Пользователя;

<li>заключение, сопровождение, изменение, расторжение договоров с Пользователем, а также исполнение обязательств, предусмотренных соответствующими договорами;

<li>информирование о вакансиях, сбор и рассмотрение резюме и подбор кандидатов на вакантную должность для дальнейшего трудоустройства;

<li>заключение, сопровождение, изменение, расторжение трудовых договоров, а также исполнение обязательств, предусмотренных соответствующими договорами и локальными нормативными актами;

<li>соблюдение законодательства, регулирующего отношения, возникающие в связи созданием и использованием произведений науки, литературы и искусства (авторское право), фонограмм, исполнений, постановок, передач организаций эфирного или кабельного вещания (смежные права);

<li>соблюдение законодательства, регулирующего правовое положение некоммерческих организаций, права и обязанности их учредителей, членов, участников, порядок создания, реорганизации и ликвидации, внесения изменений в учредительные документы и ЕГРЮЛ;

<li>внутреннее информационное обеспечение деятельности АНО «АНИМА», формирование справочных материалов и размещение их на корпоративном сайте (портале), в корпоративных новостных бюллетенях и в офисном пространстве (стенды, информационные доски и др.);

<li>исполнение обязательств, предусмотренных федеральным законодательством и иными нормативными правовыми актами.
</ul>
</p>
<p>2.2. Кроме того, АНО «АНИМА» осуществляет обработку ПДн 
по поручению других лиц на основании заключаемых с этими лицами договорами (поручениями). Состав и цели такой обработки определяются операторами, поручающими обработку ПДн АНО «АНИМА».

<p class="medium">3. ОБЪЕМ И КАТЕГОРИИ ОБРАБАТЫВАЕМЫХ ПДН, КАТЕГОРИИ СУБЪЕКТОВ ПДН.

<p>Правила обработки персональных данных

<p>3.1. В АНО «АНИМА» осуществляется обработка ПДн, принадлежащих Пользователю любого из Сервисов АНО «АНИМА» и/или лиц, входящих в одну группу с АНО «АНИМА», партнеров, в том числе:
<ul>
<li>Обладателям авторского права и смежных прав;

<li>Участникам акций, конкурсов, игр или иных подобных мероприятий для Пользователя, включая ПДн, сделанные Пользователем общедоступными<sup>1</sup>;

<li>Пользователю сайта с доменным именем <a href="www.contest2022.anima.moscow">www.contest2022.anima.moscow</a><sup>2</sup>, в том числе Пдн, сделанные Пользователем общедоступными;

<li>исполнителям по гражданско-правовым договорам, представителям и контактным лицам Пользователя;
кандидатам на вакантные должности<sup>3</sup>;

<li>работникам<sup>4</sup>.
</ul>
</p>

<p>3.2. АНО «АНИМА» осуществляет обработку следующих категорий персональных данных:фамилия, имя, отчество; год рождения; месяц рождения; дата рождения; место рождения; адрес; семейное положение; социальное положение; имущественное положение; образование; профессия; доходы; образовательные учреждения, где учился субъект персональных данных, документы об образовании, квалификация, стаж работы, состав семьи, ФИО членов семьи, паспортные данные, фактический адрес, номер телефона, ИНН, сведения о местах работы, аттестации, повышении квалификации, награды, поощрения.

<p>3.3. В АНО «АНИМА» не допускается обработка ПДн, касающихся:
<ul>
<li>расовой принадлежности субъектов персональных данных;
<li>национальной принадлежности субъектов персональных данных;
<li>политических взглядов субъектов персональных данных;
<li>религиозных убеждений субъектов персональных данных;
<li>философских убеждений субъектов персональных данных;
<li>состояния здоровья субъектов персональных данных;
<li>интимной жизни субъектов персональных данных.
</ul>
</p>
<p>В АНО «АНИМА» не обрабатываются биометрические персональные данные (сведения, которые характеризуют физиологические и биологические особенности человека, на основании которых можно установить его личность).

<p>3.4. Перечень действий, совершаемых АНО «АНИМА» с персональными данными:

<p>сбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передача (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.

<p>Способы обработки ПДн: смешанная обработка (автоматизированная, неавтоматизированная), с передачей по внутренней сети юридического лица; с передачей по сети Интернет.

<p>Осуществление трансграничной передачи персональных данных не осуществляется.

<p>Сроки обработки ПДн: в соответствии с целями обработки.

<p>3.5. В отношении ПДн Пользователя сохраняется их конфиденциальность, кроме случаев добровольного предоставления Пользователем информации о себе для общего доступа неограниченному кругу лиц. При использовании отдельных Сервисов, Пользователь соглашается с тем, что определённая часть его ПДн становятся общедоступной.

<p>3.6. АНО «АНИМА» в ходе своей деятельности может осуществлять передачу, а также поручение обработки ПДн другим лицам на основании соответствующих договоров, при условии получения согласия Пользователя или обеспечения иных необходимых правовых оснований для такой передачи.

<p>Важным условием договоров, на основании которых осуществляется передача ПДн, является обеспечение конфиденциальности<sup>5</sup> и безопасности обрабатываемых ПДн.

<p>Кроме того, АНО «АНИМА» вправе передавать персональные данные органам дознания и следствия, иным уполномоченным органам по основаниям, предусмотренным действующим законодательством.

<p>3.7. При сборе ПДн, в том числе посредством информационно-телекоммуникационной сети «Интернет», АНО «АНИМА» обеспечивает запись, систематизацию, накопление, хранение, уточнение (обновление, изменение) и извлечение ПДн с использованием баз данных, находящихся на территории Российской Федерации.

<p class="medium">4. ИЗМЕНЕНИЕ И УДАЛЕНИЕ ПЕРСОНАЛЬНЫХ ДАННЫХ. ОБЯЗАТЕЛЬНОЕ ХРАНЕНИЕ ДАННЫХ

<p>4.1. Пользователь может в любой момент изменить 
(обновить, дополнить) предоставленные им ПДн или их часть.

<p>4.2. Пользователь может в любой момент отозвать согласие 
на обработку персональных данных.

<p>4.3. В случае отзыва Пользователем согласия на обработку персональных данных, истечения срока действия согласия, а также при достижении целей обработки или утраты необходимости в достижении этих целей ПДн подлежат уничтожению, если иное не предусмотрено федеральным законом, договором, стороной которого, выгодоприобретателем или поручителем по которому является субъект персональных данных.

<p>4.4. В случае отзыва Пользователем согласия на обработку ПДн (п. 4.2 Политики) АНО «АНИМА» вправе продолжить обработку персональных данных без согласия Пользователя при наличии оснований, указанных в законе.

<p class="medium">5. ТРЕБОВАНИЯ ПО ОБЕСПЕЧЕНИЮ БЕЗОПАСНОСТИ ПЕРСОНАЛЬНЫХ ДАННЫХ

<p>5.1. В АНО «АНИМА» применяются необходимые и достаточные организационные и технические меры для защиты ПДн от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий с ними третьих лиц, включающие в себя использование средств защиты информации, обнаружение фактов несанкционированного доступа, восстановление персональных данных, установление правил доступа к персональным данным, а также контроль и оценку эффективности применяемых мер.

<p>5.2. При обработке ПДн в АНО «АНИМА» соблюдаются требования нормативных документов РФ в области обработки и обеспечения безопасности персональных данных, в том числе:
<ul>
<li>Федеральный закон от 27.07.2006 г. № 152-ФЗ «О персональных данных»;

<li>Постановление Правительства Российской Федерации от 15.09.2008 г. № 687 «Об утверждении Положения об особенностях обработки персональных данных, осуществляемой без использования средств автоматизации»;

<li>Постановление Правительства Российской Федерации от 01.11.2012 г. № 1119 «Об утверждении требований к защите персональных данных при их обработке в информационных системах персональных данных»;

<li>Приказ ФСТЭК России от 18 февраля 2013 г. № 21 «Об утверждении Состава и содержания организационных и технических мер по обеспечению безопасности персональных данных при их обработке в информационных системах персональных данных»;

<li>Приказ ФСБ России от 10.07.2014 № 378 «Об утверждении Состава и содержания организационных и технических мер по обеспечению безопасности персональных данных при их обработке в информационных системах персональных данных с использованием средств криптографической защиты информации, необходимых для выполнения установленных Правительством Российской Федерации требований к защите персональных данных для каждого из уровней защищенности»;иные нормативные акты, регулирующие правоотношения в сфере защиты ПДн.
</ul>
</p>
<p class="medium">6. ИЗМЕНЕНИЕ ПОЛИТИКИ. 
<br>ПРИМЕНИМОЕ ЗАКОНОДАТЕЛЬСТВО

<p>6.1. АНО «АНИМА» имеет право вносить изменения в настоящую Политику. При внесении изменений в актуальной редакции указывается дата последнего обновления. Новая редакция Политики вступает в силу с момента ее размещения на сайте, если иное не предусмотрено новой редакцией Политики. Действующая редакция постоянно доступна на странице по адресу <a href="www.contest2022.anima.moscow">www.contest2022.anima.moscow</a>.

<p>6.2. К настоящей Политике и отношениям между Пользователем 
и АНО «АНИМА», возникающим в связи с применением Политики, подлежит применению право Российской Федерации.

<p><sup>1</sup> — Персональные данные, сделанные субъектом персональных данных общедоступными — это персональные данные, доступ неограниченного круга лиц к которым предоставлен субъектом персональных данных либо такой доступ предоставлен по его просьбе.

<p><sup>2</sup> — В общих случаях условия размещения информации в общем доступе на Сайте не предполагают отображения фамилии, имени и отчества пользователя и каких-либо иных данных, позволяющих идентифицировать пользователя. При этом пользователь может самостоятельно указать свои полные фамилию, имя и отчество в поле «имя» при регистрации на Сайте, а также любые персональные данные, относящиеся к нему в размещаемом тексте.

<p><sup>3</sup> — Как кандидатам на вакантные должности в АНО «АНИМА», так и кандидатам на вакантные должности в другие компании, входящие в одну группу лиц с АНО «АНИМА», партнеров, чьи персональные данные обрабатываются по поручению.

<p><sup>4</sup> — Как работникам АНО «АНИМА», так и работникам других компаний, входящих в одну группу лиц с АНО «АНИМА», партнеров, чьи персональные данные обрабатываются по поручению.

<p><sup>5</sup> — За исключением персональных данных пользователя Сайта (физических лиц), сделанных субъектом общедоступными (персональных данных, указанных пользователем в тексте, размещаемом на Сайте).

<p>Дата публикации: 24 января 2022 г.

`;
