



















import { Component, Emit, Vue } from 'vue-property-decorator';

@Component
export default class LogoSmall extends Vue {
  @Emit()
  public click() {
    return;
  }
}
