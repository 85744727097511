


























































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import GalleryGroup from '@/components/GalleryGroup.vue'
import GalleryWork from '@/models/GalleryWork';
import GalleryItemInfo from '@/components/GalleryItemInfo.vue';


@Component({
  components: {
    GalleryGroup,
    GalleryItemInfo,
    Keypress: () => import('vue-keypress'),
  },
})
export default class GalleryFull extends Vue {
  @Prop({ required: true }) public work!: GalleryWork;

  get language() {
    return this.$route.params.language || 'ru';
  }

  @Emit()
  public closeFull() {
    return
  }
  @Emit()
  public prew() {
    return
  }
  @Emit()
  public next() {
    return
  }
}
