












import { Component, Vue } from 'vue-property-decorator';
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";

@Component({
  components: {
    Header,
    Footer,
  },
})
export default class App extends Vue {
  get isMenuOpen() {
    return this.$store.getters.isMenuOpen;
  }
}
