import axios from 'axios';
import BaseUrl from '@/constants/BaseUrl';
import Store from '../store';

const baseURL = BaseUrl;

export default class Http {
  /**
     * Core-метод для get-запроса
     */
  public static get(url: string, rawParams?: object): Promise<any> {
    const params = this.getParams(rawParams);

    return axios({
      method: 'get',
      baseURL,
      url,
      params,
      // transformResponse: this.transformResponse,
    });
  }

  /**
     * Core-метод для post-запроса
     */
  public static post(url: string, rawParams?: object, withToken?: boolean): Promise<any> {
    let data = rawParams;
    const headers: {[key: string]: string} = {
      'Content-Type': 'application/x-www-form-urlencoded',
    }

    if (withToken) {
      data = this.getParams(rawParams, Store.getters.token);
    }

    return axios({
      method: 'post',
      baseURL,
      url,
      data,
      headers,
      transformResponse: this.transformResponse,
    });
  }

  /**
     * Функция для получения url
     */
  private static getParams(rawParams?: object, token?: string): any {
    return {
      token,
      data: rawParams,
    }
  }

  /**
     * Функция-interceptor для запросов
     */
  private static transformResponse(response: any): any {
    const regex = /<div/;
    if (regex.test(response)) {
      return Promise.reject(response);
    }
    const responseObj = JSON.parse(response);
    if (responseObj.token) {
      Store.commit('setToken', responseObj.token)
    }
    if (responseObj.status !== 'success') {
      return Promise.reject(responseObj);
    }
    return responseObj;
  }
}
