import Vue from 'vue'
import Vuex from 'vuex'

import commonStorage from './common.storage';
import authStorage from './auth.storage';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    commonStorage,
    authStorage,
  }
})
