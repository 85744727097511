









import { Component, Vue } from 'vue-property-decorator';
import moment from "moment";

const secondsInMinute = 60;
const secondsInHour = 60 * secondsInMinute;
const secondsInDay = 24 * secondsInHour;

@Component({
  filters: {
    addNull(number: number) {
      return number < 10 ? `0${number}` : number;
    }
  }
})
export default class Countdown extends Vue {

  public now = moment().unix();

  get language() {
    return this.$route.params.language || 'ru';
  }
  get endDate() {
    return this.$store.getters.endRegisterPeriod.unix();
  }
  get isEndRegisterPeriod() {
    return this.$store.getters.isEndRegisterPeriod;
  }
  get countDown() {
    return this.endDate - this.now;
  }
  get lastSeconds() {
    return this.countDown % secondsInDay;
  }
  get hours() {
    return Math.floor(this.lastSeconds / (60 * 60));
  }
  get minutes() {
    return Math.floor((this.lastSeconds - this.hours * secondsInHour) / secondsInMinute);
  }
  get seconds() {
    return this.lastSeconds - this.hours * secondsInHour - this.minutes * secondsInMinute;
  }
  get days() {
    return Math.floor(this.countDown / secondsInDay);
  }

  public countDownTimer() {
    if(this.countDown > 0) {
      setTimeout(() => {
        this.now = moment().unix();
        this.countDownTimer();
      }, 1000)
    }
  }
  public daysFormat(days: number) {
    const cases = [2, 0, 1, 1, 1, 2];
    const translations: { [lang: string]: string[] } = {
      ru: ['день', 'дня', 'дней'],
      it: ['giorno', 'giorni', 'giorni'],
    }
    return translations[this.language][(days % 100 > 4 && days % 100 < 20) ? 2 : cases[(days % 10 < 5) ? days % 10 : 5]];
  }

  public created() {
    this.countDownTimer();
  }
}
