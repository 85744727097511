
















import { Component, Vue } from 'vue-property-decorator';
import RouterNames from "@/router/RouterNames";
import MainMenu from "@/components/MainMenu.vue";
import LogoMain from "@/components/LogoMain.vue";
import Language from "@/components/Language.vue";
import Footer from "@/components/Footer.vue";
import IconClose from "@/components/IconClose.vue";

@Component({
  components: {
    MainMenu,
    LogoMain,
    Language,
    Footer,
    IconClose,
  },
})
export default class MobileMenu extends Vue {
  public RouterNames = RouterNames;

  public toggleMenu() {
    this.$store.dispatch('toggleIsMenuOpen')
  }
}
