




































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component
export default class GalleryFilter extends Vue {
  @Prop({ required: true }) public en!: string[];
  @Prop({ required: true }) public ru!: string[];

  public open = false;
  public selectedLetter = '';

  get language() {
    return this.$route.params.language || 'ru';
  }
  get groups() {
    return this.language === 'ru' ? [ this.ru, this.en ] : [ this.en, this.ru ];
  }
  get mobile() {
    return screen.width < 1140;
  }

  @Emit()
  public select() {
    return this.selectedLetter;
  }

  public resetLetter() {
    this.selectedLetter = '';
    this.select();
  }
  public selectLetter(letter: string) {
    this.selectedLetter = letter.toLowerCase();
    this.select();
  }
}
