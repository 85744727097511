



























































import { Component, Prop, Vue } from 'vue-property-decorator';
import GalleryGroup from '@/components/GalleryGroup.vue'
import GalleryWork from '@/models/GalleryWork';
import moment, { Moment } from 'moment';
import GalleryFull from '@/components/GalleryFull.vue';
import GalleryFilter from '@/components/GalleryFilter.vue';
import GalleryGroupParams from '@/models/GalleryGroups';

function getUniqueLetters(array: GalleryWork[]) {
  const allLetters = array
    .reduce((acc: string[], next: GalleryWork) => {
      const letter = next.authors
        .trim()
        .split(' ')
        .map((word: string) => word.slice(0, 1).toLowerCase())[1];
      acc = [
        ...acc,
        letter,
      ]
      return acc;
    }, []).sort()
  return [...new Set(allLetters)];
}

@Component({
  components: {
    GalleryGroup,
    GalleryFull,
    GalleryFilter,
  },
})
export default class Gallery extends Vue {
  @Prop({ type: Boolean, default: false }) public winners!: boolean;
  @Prop({ type: Boolean, default: false }) public other!: boolean;

  public fullWork: GalleryWork | false = false;
  public selectedLetter = '';

  get allWorksAfterGrouping(): Array<GalleryWork> {
    return this.allGroups.reduce((acc: Array<GalleryWork>, next) => {
      return [ ...acc, ...next.works ];
    }, [])
  }

  get galleryWorks() {
    if (this.winners) {
      return this.$store.getters.galleryTopWorks;
    }
    if (this.other) {
      return this.$store.getters.galleryOtherWorks;
    }
    return this.$store.getters.galleryWorks;
  }
  get winnersArray() {
    return this.galleryWorks.filter(({ winner }: GalleryWork) => winner);
  }
  get winnersGroups() {
    const groups: { [id: number]: GalleryGroupParams } = this.winnersArray
      .reduce((acc: { [id: number]: GalleryGroupParams }, next: GalleryWork) => {
        const dateFormat = 'YYYY-MM-DD HH:mm:ss';
        if (!acc[next.winner]) {
          acc[next.winner] = {
            date: moment(next.created, dateFormat),
            id: next.winner * 1000,
            works: [],
            winner: next.winner,
          };
        }
        const group = acc[next.winner];
        if (group.date > moment(next.created, dateFormat)) {
          group.date = moment(next.created, dateFormat);
        }
        group.works.push(next);
        return acc;
      }, {})
    return Object.values(groups)
      .sort((a, b) => {
        if (a.winner && b.winner) {
          return a.winner < b.winner ? -1 : 1;
        }
        return 0;
      })
  }

  get notWinnersArray() {
    return this.galleryWorks.filter(({ winner }: GalleryWork) => !winner);
  }
  get notWinnersGroup() {
    const works = [...this.notWinnersArray];
    const dateFormat = 'YYYY-MM-DD HH:mm:ss';
    return {
      date: moment(works[0]?.created, dateFormat),
      id: works[0]?.userId,
      works,
    }
  }
  get allGroups() {
    const groups: { [id: number]: GalleryGroupParams } = this.galleryWorks
      .reduce((acc: { [id: number]: GalleryGroupParams }, next: GalleryWork) => {
        const dateFormat = 'YYYY-MM-DD HH:mm:ss';
        if (!acc[next.userId]) {
          acc[next.userId] = {
            date: moment(next.created, dateFormat),
            id: next.userId,
            works: [],
          };
        }
        const group = acc[next.userId];
        if (group.date > moment(next.created, dateFormat)) {
          group.date = moment(next.created, dateFormat);
        }
        group.works.push(next);
        return acc;
      }, {})
    return Object.values(groups)
      .sort((a, b) => {
        return a.date < b.date ? -1 : 1;
      })
  }

  get isWorksLoading() {
    return this.$store.getters.isWorksLoading;
  }
  get allRuLetters() {
    return getUniqueLetters(this.galleryWorks.filter((work: GalleryWork) => work.language === 'ru'));
  }
  get allEnLetters() {
    return getUniqueLetters(this.galleryWorks.filter((work: GalleryWork) => work.language === 'it'));
  }
  get filteredWorks() {
    return {
      works: this.galleryWorks.filter((work: GalleryWork) => {
        const firstLetterFamily = work.authors
          .split(' ')
          .map((word: string) => word.slice(0, 1).toLowerCase())[1];
        return firstLetterFamily === this.selectedLetter;
      }),
    };
  }

  public openFull(work: GalleryWork) {
    this.fullWork = work;
  }
  public closeFull() {
    this.fullWork = false;
  }
  public nextFull() {
    const index = this.allWorksAfterGrouping.findIndex(work => this.fullWork && work.id === this.fullWork.id)
    if (index === this.allWorksAfterGrouping.length - 1) {
      this.fullWork = false;
    }
    this.fullWork = this.allWorksAfterGrouping[index + 1];
  }
  public prewFull() {
    const index = this.allWorksAfterGrouping.findIndex(work => this.fullWork && work.id === this.fullWork.id)
    if (!index) {
      this.fullWork = false;
    }
    this.fullWork = this.allWorksAfterGrouping[index - 1];
  }

  public filterWorks(letter: string) {
    this.selectedLetter = letter;
  }

  public created() {
    if (this.winners) {
      this.$store.dispatch('loadTopWorks');
    } else if (this.other) {
      this.$store.dispatch('loadOtherWorks');
    } else {
      this.$store.dispatch('loadAllWorks');
    }
  }
}
