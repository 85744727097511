export const licenseRu = `
<p class="medium">УСЛОВИЯ ЛИЦЕНЗИОННОГО ДОГОВОРА</p>

  <p class="medium">1. ПРЕДМЕТ ДОГОВОРА</p>
    <p>1.1. Предметом настоящего договора является предоставление Направляющей стороной Принимающей стороне прав на произведения (далее — Конкурсные работы) с целью использования в рамках Художественного конкурса «RUSSIA — ITALIA. 2022. RIFLESSI / РОССИЯ — ИТАЛИЯ. 2022. ОТРАЖЕНИЕ» (далее также - Конкурс), проводится некоммерческой организацией «АНИМА (Душа). Центр культурных инноваций», Ассоциацией школ Рима — Scuola Maestra APS (ScuolediRoma. it) в партнерстве с RENALIART (Национальная сеть художественных лицеев — Rete Nazionale Licei Artistici).
    <p>1.2. Конкурсные работы, права на которые предоставляются в рамках настоящего Договора, определяются Направляющей стороной путем загрузки на странице Сайта <a href="www.contest2022.anima.moscow">www.contest2022.anima.moscow</a> скана или фотографии таких работ.
    <p>1.3. Порядок, территория и сроки проведения Конкурса определены Правилами конкурса (далее также Правила)

  <p class="medium">2. ОБЯЗАННОСТИ СТОРОН
    <p>2.1. Направляющая сторона обязуется:
    <p>2.1.1. передать Принимающей стороне Конкурсные работы и информацию об их авторах в порядке и на условиях, установленных настоящим Договором;
    <p>2.1.2. Предоставить Принимающей стороне право использования Конкурсных работ на территории всего мира следующими способами:
    <p>2.1.2.1. воспроизведение, 
    <p>2.1.2.2. распространение, 
    <p>2.1.2.3. публичный показ, 
    <p>2.1.2.4. импорт в целях распространения;
    <p>2.1.2.5. сообщение в эфир, сообщение по кабелю;
    <p>2.1.2.6. доведение до всеобщего сведения.
    <p>2.1.3. Направляющая сторона гарантирует наличие у неё всех прав на использование Конкурсных работ (в том числе как объектов авторских прав) и несёт всю ответственность 
по возможным претензиям третьих лиц, связанным 
с предоставлением Конкурсных работ.
    <p>2.1.4. Права, указанные в п.п. 2.1.2 настоящего Договора, считаются переданными с момента загрузки на странице Сайта <a href="www.contest2022.anima.moscow">www.contest2022.anima.moscow</a> скана или фотографии Конкурсных работ, оформление акта передачи прав не требуется. Права предоставляются Принимающей стороне до окончания срока проведения Конкурса, установленного Правилами. Любое дальнейшее использование Конкурсных работ возможно только по согласованию с Направляющей стороной и должно быть оформлено Дополнительным соглашением к настоящему Договору. Направляющая сторона предоставляет принимающей стороне указанные права безвозмездно.
    <p>2.2. Принимающая сторона обязуется:
    <p>2.2.1. Использовать Конкурсные работы только в рамках Конкурса.
    <p>2.2.2. Принимающая сторона вправе привлечь третьих лиц для исполнения своих обязательств по настоящему договору, при этом несет ответственность за их действия (бездействия).

  <p class="medium">3. ОБСТОЯТЕЛЬСТВА НЕПРЕОДОЛИМОЙ СИЛЫ
    <p>3.1.  При наступлении обстоятельств полной или частичной невозможности исполнения любой из сторон обязательств по настоящему договору, а именно, стихийных бедствий, блокады, угрозы распространения заболеваний и введения в этой связи режима чрезвычайной ситуации или повышенной готовности, изменений законодательства или других независящих от сторон обстоятельств, срок исполнения обязательств отодвигается соразмерно времени, в течение которого будет действовать такие обстоятельства и их последствия. Если эти обстоятельства и их последствия будут продолжаться более 3 (трех) месяцев, то каждая из сторон будет иметь право отказаться от дальнейшего исполнения обязательств по договору, и в этом случае ни одна из сторон не будет иметь права на возмещение другой стороной возможных убытков.
    <p>3.2. Сторона, для которой создалась невозможность исполнения обязательств по договору, должна о наступлении и прекращении обстоятельств, препятствующих исполнению этих обязательств, немедленно извещать другую сторону.

  <p class="medium">4. ПРИМЕНИМОЕ ПРАВО
    <p>4.1.  Правом, применимым к отношениям сторон по заключению, исполнению, расторжению настоящего Договора и иным отношениям сторон, является законодательство Российской Федерации.
    <p>4.2. Все споры и разногласия, которые могут возникнуть из настоящего Договора или в связи с его исполнением, подлежат разрешению путем непосредственных переговоров сторон. 
Если такие переговоры не дадут результатов, то споры подлежат рассмотрению в судебном порядке по месту нахождения Принимающей стороны в соответствии с законодательством Российской Федерации.

  <p class="medium">5. ОБЩИЕ ПОЛОЖЕНИЯ
    <p>5.1.  Договор составлен в 2-х экземплярах, каждый из которых имеет одинаковую юридическую силу.
    <p>5.2. Настоящий договор вступает в силу с момента его подписания обеими сторонами и действует до полного выполнения сторонами своих обязательств.
    <p>5.3. Скан подписанного и заверенного печатью настоящего договора имеет такую же юридическую силу, как и оригинал подписанного и заверенного печатью договора. и действует до момента обмена оригинальными экземплярами. Оригинальные экземпляры должны быть предоставлены по запросу.
`


export const licenseIt = `
<p class="medium">TERMINI DEL CONTRATTO DI CONCESSIONE DI LICENZA</p>

  <p class="medium">1. OGGETTO DEL CONTRATTO</p>
    <p>1.1.  L’oggetto del presente contratto è costituito dalla concessione alla Parte ricevente, da parte della Parte mittente, dei diritti alle opere (si seguito denominate Opere in concorso) con l’obiettivo di utilizzarle nell’ambito del Concorso artistico “ITALIA —RUSSIA. 2022. RIFLESSI / РОССИЯ — ИТАЛИЯ. 2022. ОТРАЖЕНИЕ” (denominato anche Concorso di seguito), svolto dall’organizzazione senza fini di lucro “ANIMA. Centro di innovazioni culturali” e dall’Associazione delle scuole di Roma Scuola Maestra APS (ScuolediRoma.it), in partenariato con RENALIART (Rete Nazionale Licei Artistici).
    <p>1.2.  Le Opere in concorso i cui diritti vengono concessi nell’ambito del presente Contratto vengono definite dalla Parte mittente tramite il caricamento, alla pagina del Sito <a href="www.contest2022.anima.moscow">www.contest2022.anima.moscow</a>, di una scansione o una foto di tali opere.
    <p>1.3.  Le modalità, il territorio e i tempi di svolgimento del Concorso sono definite dal Regolamento del concorso (denominato anche Regolamento di seguito).

  <p class="medium">2. DOVERI DELLE PARTI
    <p>2.1.  La parte mittente si impegna a:
    <p>2.1.1. trasferire alla Parte ricevente Opere in concorso e le informazioni concernenti i rispettivi autori nelle modalità e a condizioni stabilite dal presente Contratto;
    <p>2.1.2. Concedere alla Parte ricevente il diritto di utilizzare le Opere in concorso nel territorio globale con le seguenti modalità:
    <p>2.1.2.1. riproduzione, 
    <p>2.1.2.2. diffusione, 
    <p>2.1.2.3. mostre pubbliche, 
    <p>2.1.2.4. importazione ai fini di diffusione;
    <p>2.1.2.5. trasmissione in onda, trasmissione via cavo;
    <p>2.1.2.6. informazione universale.
    <p>2.1.3. La parte mittente garantisce di possedere tutti i diritti all’utilizzo delle Opere in concorso (anche come oggetti dei diritti d’autore) ed è pienamente responsabile nel caso di eventuali reclami di persone terze riguardanti la concessione delle Opere in concorso.
    <p>2.1.4. I diritti indicati ai co. 2.1.2. del presente Contratto si considerano concessi dal momento del caricamento, alla pagina del Sito <a href="www.contest2022.anima.moscow">www.contest2022.anima.moscow</a>, di una scansione o una foto delle Opere in concorso, non è richiesta la formalizzazione di un atto di concessione dei diritti. I diritti vengono concessi alla Parte ricevente fino alla scadenza del termine di svolgimento del Concorso stabilita dal Regolamento. Qualsiasi ulteriore utilizzo delle Opere in concorso è possibile esclusivamente previo accordo con la Parte mittente e va formalizzato tramite un Accordo addizionale al presente Contratto. La Parte mittente concede i diritti sopraindicati alla Parte ricevente a titolo gratuito.
    <p>2.2. La parte ricevente si impegna a:
    <p>2.2.1. Utilizzare le Opere in concorso soltanto nell’ambito del Concorso.
    <p>2.2.2. La parte ricevente ha il diritto di coinvolgere persone terze per adempiere ai propri obblighi previsti dal presente Contratto ed è responsabile delle azioni (omissioni) di queste ultime.

  <p class="medium">3. CAUSE DI FORZA MAGGIORE
    <p>3.1.  Al verificarsi di circostanze di piena o parziale impossibilità di adempimento, da parte di qualsiasi delle due Parti, ai propri doveri previsti dal presente Contratto, e cioè di calamità naturali, assedio, rischio di diffusione di malattie e conseguente introduzione del regime di emergenza o di allerta, modifiche della legislazione o altre circostanze che non dipendono dalle parti, la scadenza dell’adempimento ai propri doveri viene rinviata proporzionalmente al tempo di persistenza di tali circostanze e delle relative conseguenze. Se tali circostanze e le relative conseguenze dovessero perdurare per più di 3 (tre) mesi, ognuna delle due Parti avrà il diritto di rinunciare all’ulteriore adempimento dei propri doveri contrattuali, e in quel caso nessuna delle due Parti avrà il diritto al risarcimento, da parte dell’altra Parte, degli eventuali danni.
    <p>3.2. La Parte per la quale si è venuta a creare l’impossibilità di adempire ai doveri contrattuali deve notificare immediatamente l’altra Parte del sorgere e venire meno di circostanze che impediscono l’adempimento a tali doveri. 

  <p class="medium">4. LEGGE APPLICABILE
    <p>4.1.  Il diritto applicabile ai rapporti tra le due Parti per la stipula, l’esecuzione e la rescissione del presente contratto nonché agli altri rapport tra le due Parti è rappresentato dalla legislazione della Federazione Russa.
    <p>4.2. Tutte le controversie e le divergenze che possono sorgere in relazione al presente Contratto o alla sua esecuzione vanno risolte tramite un negoziato diretto tre le Parti. Se tale negoziato non dovesse produrre risultati, le controversie vanno esaminate per vie legali sulla base della posizione della sede della Parte ricevente e in conformità alla legislazione della Federazione Russa.

  <p class="medium">5. DISPOSIZIONI GENERALI
    <p>5.1.  Il Contratto è redatto in duplice copia, ciascuna delle quali ha pari valore legale.
    <p>5.2. Il presente Contratto entra in vigore a partire dalla firma da parte di entrambe le Parti e mantiene la propria validità fino alla piena esecuzione dei propri doveri da parte delle due Parti.
    <p>5.3. La scansione del presente Contratto firmato e timbrato ha un valore legale pari a quello dell’originale del Contratto firmato e timbrato e rimane valida fino al momento dello scambio di copie originali. Le copie originali vanno messe a disposizione su richiesta.
`
