import { ActionContext } from 'vuex';
import { UserApi } from '@/api';
import Work from '@/models/Work';
import RegisteredWork, { RegisteredWorkParams } from '@/models/RegisteredWork';
import GalleryWork, { GalleryWorkParams } from '@/models/GalleryWork';
import moment, { Moment } from 'moment';
import { WorkTypes } from '@/constants';
import router from "@/router";

interface CommonStorage {
  isMenuOpen: boolean;
  works: Array<RegisteredWork>;
  galleryWorks: Array<GalleryWork>;
  galleryTopWorks: Array<GalleryWork>;
  galleryOtherWorks: Array<GalleryWork>;
  isWorksLoading: boolean;
  isGalleryPeriod: boolean;
  endRegisterPeriod: Moment;
  isEndRegisterPeriod: boolean;
  realEndRegisterPeriod: Moment;
  isRealEndRegisterPeriod: boolean;
  isResultPeriod: boolean;
  isUploadEnabled: boolean;
  isFullyLocked: boolean;
}

enum Stages {
  register,
  voting,
  gallery,
}

const dateFormat = 'YYYY-MM-DD hh';
const formattedMoment = (date: string) => moment(date, dateFormat);
const endRegisterPeriod = formattedMoment('2022-04-01 00');
const realEndRegisterPeriod = formattedMoment('2022-04-04 00');
const galleryPeriod = formattedMoment('2022-09-11 00');
const resultPeriod = formattedMoment('2022-09-15 00');

export default {
  state: {
    isMenuOpen: false,
    works: [],
    galleryWorks: [],
    galleryTopWorks: [],
    galleryOtherWorks: [],
    isWorksLoading: false,
    isGalleryPeriod: moment() > galleryPeriod,
    endRegisterPeriod,
    isEndRegisterPeriod: moment() > endRegisterPeriod,
    realEndRegisterPeriod,
    isRealEndRegisterPeriod: moment() > realEndRegisterPeriod,
    isResultPeriod: moment() > resultPeriod,
    isUploadEnabled: true,
    isFullyLocked: true,
  } as CommonStorage,
  getters: {
    isMenuOpen(state: CommonStorage): boolean {
      return state.isMenuOpen;
    },
    works(state: CommonStorage): Array<RegisteredWork> {
      return state.works;
    },
    galleryWorks(state: CommonStorage): Array<GalleryWork> {
      return state.galleryWorks;
    },
    galleryTopWorks(state: CommonStorage): Array<GalleryWork> {
      return state.galleryTopWorks;
    },
    galleryOtherWorks(state: CommonStorage): Array<GalleryWork> {
      return state.galleryOtherWorks;
    },
    isWorksLoading(state: CommonStorage): boolean {
      return state.isFullyLocked ? false : state.isWorksLoading;
    },
    isGalleryPeriod(state: CommonStorage): boolean {
      return state.isFullyLocked ? false : state.isGalleryPeriod;
    },
    endRegisterPeriod(state: CommonStorage): Moment {
      return state.endRegisterPeriod;
    },
    isEndRegisterPeriod(state: CommonStorage): boolean {
      return state.isFullyLocked ? false : state.isEndRegisterPeriod;
    },
    isRegisterPeriod(state: CommonStorage): boolean {
      return state.isFullyLocked ? false : !state.isEndRegisterPeriod;
    },
    realEndRegisterPeriod(state: CommonStorage): Moment {
      return state.realEndRegisterPeriod;
    },
    isRealEndRegisterPeriod(state: CommonStorage): boolean {
      return state.isFullyLocked ? false : state.isRealEndRegisterPeriod;
    },
    isResultPeriod(state: CommonStorage): boolean {
      return state.isFullyLocked ? false : state.isResultPeriod;
    },
    isUploadEnabled(state: CommonStorage): boolean {
      return state.isFullyLocked ? false : ( state.isUploadEnabled || Boolean(router.currentRoute.query.forceUpload) );
    },
    isFullyLocked(state: CommonStorage): boolean {
      return state.isFullyLocked;
    },
  },
  mutations: {
    setIsMenuOpen(state: CommonStorage, value = false) {
      state.isMenuOpen = value;
    },
    setIsWorksLoading(state: CommonStorage, value = false) {
      state.isWorksLoading = value;
    },
    setWorks(state: CommonStorage, value: RegisteredWork[]) {
      state.works = value;
    },
    setGalleryWorks(state: CommonStorage, value: GalleryWork[]) {
      state.galleryWorks = value;
    },
    setGalleryTopWorks(state: CommonStorage, value: GalleryWork[]) {
      state.galleryTopWorks = value;
    },
    setGalleryOtherWorks(state: CommonStorage, value: GalleryWork[]) {
      state.galleryOtherWorks = value;
    },
  },
  actions: {
    toggleIsMenuOpen({ state, commit }: ActionContext<CommonStorage, any>) {
      commit('setIsMenuOpen', !state.isMenuOpen);
    },
    uploadWorks({ state, commit, dispatch }: ActionContext<CommonStorage, any>, params: Work[]) {
      return UserApi.postUploadWorks(params).catch((error) => {
        if (error.code === 1007) {
          dispatch('logout');
        }
        return error;
      });
    },
    downloadWorks({ state, commit, dispatch }: ActionContext<CommonStorage, any>) {
      commit('setIsWorksLoading', true);
      return UserApi.getWorks().then((response: RegisteredWorkParams[]) => {
        const works = response.map((params: RegisteredWorkParams) => new RegisteredWork(params));
        commit('setWorks', works);
      }).catch((error) => {
        if (error.code === 1007) {
          dispatch('logout');
        }
      }).finally(() => {
        commit('setIsWorksLoading');
      })
    },
    deleteWork({ state, commit, dispatch }: ActionContext<CommonStorage, any>, id: number) {
      return UserApi.postDeleteWork({ id }).then(() => {
        commit('setWorks', state.works.filter((work: RegisteredWork) => work.id !== id));
      }).catch((error) => {
        if (error.code === 1007) {
          dispatch('logout');
        }
      });
    },
    editWork({ state, commit, dispatch }: ActionContext<CommonStorage, any>, work: RegisteredWork) {
      return UserApi.postEditWork(work).catch((error) => {
        if (error.code === 1007) {
          dispatch('logout');
        }
      });
    },
    loadAllWorks({ state, commit, dispatch }: ActionContext<CommonStorage, any>) {
      commit('setIsWorksLoading', true);
      return UserApi.getAllWorks().then((response) => {
        const works = response.map((params: GalleryWorkParams) => new GalleryWork(params));
        commit('setGalleryWorks', works);
      }).finally(() => {
        commit('setIsWorksLoading');
      });
    },
    loadTopWorks({ state, commit, dispatch }: ActionContext<CommonStorage, any>) {
      commit('setIsWorksLoading', true);
      return UserApi.getAllWorks(WorkTypes.top50).then((response) => {
        const works = response.map((params: GalleryWorkParams) => new GalleryWork(params));
        commit('setGalleryTopWorks', works);
      }).finally(() => {
        commit('setIsWorksLoading');
      });
    },
    loadOtherWorks({ state, commit, dispatch }: ActionContext<CommonStorage, any>) {
      commit('setIsWorksLoading', true);
      return UserApi.getAllWorks(WorkTypes.other).then((response) => {
        const works = response.map((params: GalleryWorkParams) => new GalleryWork(params));
        commit('setGalleryOtherWorks', works);
      }).finally(() => {
        commit('setIsWorksLoading');
      });
    },
  },
};
