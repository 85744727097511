









































































































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import FormValidator from '@/services/FormValidator';
import AnimaButton from '@/components/AnimaButton.vue';
import VueRecaptcha from 'vue-recaptcha';
import { UploadItem } from '@/api/user.api';
import AnimaInput from '@/components/AnimaInput.vue';
import VueI18n from 'vue-i18n';
import { EMPTY_FRAME, Orientations } from '@/constants';
import TranslateResult = VueI18n.TranslateResult;

@Component({
  components: {
    AnimaButton,
    VueRecaptcha,
    AnimaInput,
  },
})
export default class WorkForm extends Vue {
  @Prop({ required: true }) public form!: UploadItem;
  @Prop() public index!: number;
  @Prop({ type: Boolean, default: false }) public divided!: boolean;

  public valid = true;
  public Orientations = Orientations;
  public image: string | ArrayBuffer | null = null;

  get vform(): HTMLFormElement {
    return this.$refs.workForm as HTMLFormElement;
  }

  get preview(): string | ArrayBuffer {
    return this.image ?? this.form.img ?? EMPTY_FRAME;
  }

  get rules() {
    return {
      required: [FormValidator.required],
      image: [
        (value: File) => Boolean(this.form.img) || Boolean(value) || this.$t('Errors.required'),
        (value: File) => Boolean(this.form.img) || !value || value.size < 10_000_000 || this.$t('Errors.imageSize'),
      ],
    };
  }

  get ages(): Array<{ text: TranslateResult; value: number }> {
    return [15, 16, 17, 18, 19].map((value: number) => {
      return {
        text: `${this.$t('Upload.form.ageNumber', { number: value })}`,
        value,
      };
    });
  }

  public getBase64(): void {
    const reader = new FileReader();
    const image = this.form.image as File;
    reader.readAsDataURL(image);
    reader.onloadend = () => {
      this.image = reader.result;
    };
  }

  @Emit()
  public success() {
    return;
  }
}
