


















import { Component, Vue } from 'vue-property-decorator';
import Language from "@/components/Language.vue";
import MainMenu from "@/components/MainMenu.vue";
import LogoMain from "@/components/LogoMain.vue";
import LogoSmall from "@/components/LogoSmall.vue";
import MobileMenu from "@/components/MobileMenu.vue";

@Component({
  components: {
    Language,
    LogoMain,
    MainMenu,
    LogoSmall,
    MobileMenu,
  },
})
export default class Header extends Vue {
  get isMenuOpen() {
    return this.$store.getters.isMenuOpen;
  }
  public toggleMenu() {
    this.$store.dispatch('toggleIsMenuOpen')
  }
}
