










import {Component, Emit, Vue} from 'vue-property-decorator';

@Component
export default class IconClose extends Vue {
  @Emit()
  public click() {
    return;
  }
}
