




























import { Component, Vue } from 'vue-property-decorator';
import RegisterForm from '@/components/forms/RegisterForm.vue';
import LoginForm from '@/components/forms/LoginForm.vue';
import { LocalKeys } from '@/constants/LocalKeys';
import UploadForm from '@/components/forms/UploadForm.vue';
import RegisteredWorks from '@/components/RegisteredWorks.vue';

@Component({
  components: {
    RegisterForm,
    LoginForm,
    UploadForm,
    RegisteredWorks,
  },
})
export default class RegisterAndUpload extends Vue {
  public tab = 0;
  public mobile = false;

  get authorized(): boolean {
    return this.$store.getters.authorized;
  }
  get works() {
    return this.$store.getters.works;
  }
  get isRealEndRegisterPeriod() {
    return this.$store.getters.isRealEndRegisterPeriod;
  }
  get isUploadEnabled() {
    return this.$store.getters.isUploadEnabled;
  }

  public changeTab(tab: number) {
    this.tab = tab;
    localStorage.setItem(String(LocalKeys.registerTab), String(tab));
  }

  public mounted() {
    this.mobile = screen.width < 1140;
    window.onresize = () => {
      this.mobile = screen.width < 1140;
    };
    const localTab = localStorage.getItem(String(LocalKeys.registerTab));
    if (localTab) {
      this.tab = Number(localTab);
    }
  }
}
