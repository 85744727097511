



































import { Component, Emit, Vue } from 'vue-property-decorator';
import AnimaButton from '@/components/AnimaButton.vue';
import VueRecaptcha from 'vue-recaptcha';
import WorkForm from '@/components/forms/WorkForm.vue';
import { UploadItem } from '@/api/user.api';
import Work from '@/models/Work';
import Author from "@/models/Author";

@Component({
  components: {
    AnimaButton,
    WorkForm,
  },
})
export default class UploadForm extends Vue {
  public valid = true;
  public successScreen = false;
  public sending = false;

  public works: UploadItem[] = [];
  public worksLimit = 10;

  get modifier() {
    return 1 + this.registeredWorks.length;
  }

  get registeredWorks() {
    return this.$store.getters.works;
  }

  get language() {
    return this.$route.params.language || 'ru';
  }

  get loading() {
    return this.$store.getters.isWorksLoading || this.sending;
  }

  get vform(): HTMLFormElement[] {
    return this.$refs.workForm as HTMLFormElement[];
  }

  get recaptcha(): VueRecaptcha {
    return this.$refs.recaptcha as VueRecaptcha;
  }

  get possibleAddWork() {
    return this.works.length < this.worksLimit;
  }

  @Emit()
  public success() {
    return
  }

  public addWork() {
    if (this.possibleAddWork) {
      this.works.push({
        title: '',
        description: '',
        authors: [new Author({})],
      });
    }
  }

  public resetWorks() {
    this.works = [];
  }

  public action() {
    const validated = this.vform.reduce((result, form) => {
      return result && form.$refs.workForm.validate();
    }, true)
    if (validated) {
      this.successScreen = false;
      this.sending = true;
      const results = this.works
        .map((work: UploadItem) => new Work(work))
        .map((work: Work) => work.getParams());
      Promise.all(results).then((params) => {
        this.$store.dispatch('uploadWorks', params).then(() => {
          this.resetWorks();
          this.$store.dispatch('downloadWorks');
          this.successScreen = true;
          this.$notify({
            type: 'success',
            title: String(this.$t('Upload.success')),
            message: '',
            duration: 0,
          });
        }).catch((error) => {
          this.$notify({
            type: 'error',
            title: String(this.$t('Errors.unexpected')),
            message: '',
            duration: 0,
          });
        }).finally(() => {
          this.sending = false;
        });
      });
    }
  }

  public created() {
    this.addWork();
  }

}
