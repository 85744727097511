import Author from '@/models/Author';
import { OrientationsType } from '@/constants';

export interface RegisteredWorkParams {
  title: string;
  description: string;
  authors: Author[];
  creationPlace?: string; // deprecated
  img: string;
  id: number;
  orientation: OrientationsType;
  material?: string;
  width?: number;
  height?: number;
}

export default class RegisteredWork {
  public title: string;
  public description: string;
  public authors: Author[];
  public creationPlace?: string; // deprecated
  public img: string; // URL
  public id: number;
  public orientation: OrientationsType;
  public material?: string;
  public width?: number;
  public height?: number;

  constructor(props: RegisteredWorkParams) {
    this.title = props.title;
    this.description = props.description;
    this.authors = props.authors;
    this.creationPlace = props.creationPlace;
    this.img = props.img;
    this.id = props.id;
    this.material = props.material;
    this.orientation = props.orientation;
    this.width = props.width;
    this.height = props.height;
  }
}
