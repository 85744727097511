














































































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import GalleryWork from '@/models/GalleryWork';
import GalleryItem from '@/components/GalleryItem.vue';
import GalleryGroupParams from '@/models/GalleryGroups';


@Component({
  components: {
    GalleryItem,
  },
})
export default class GalleryGroup extends Vue {
  @Prop({ required: true }) public group!: GalleryGroupParams;
  @Prop({ type: Boolean, default: false }) public winners!: boolean;

  @Emit()
  public openFull(work: GalleryWork) {
    return work;
  }

  get groupBySchool(): Array<Array<GalleryWork>> {
    const groups = this.group.works.reduce((acc: { [id: number]: GalleryWork[] }, next: GalleryWork) => {
      if (!acc[next.userId]) {
        acc[next.userId] = [];
      }
      acc[next.userId].push(next);
      return acc;
    }, {});
    return Object.values(groups);
  }

  get isTop1(): boolean {
    return this.group.winner === 1;
  }
  get colNum(): number {
    return this.group.winner && this.winners ? 2 : 3;
  }
  get columns() {
    const cols: Array<{ size: number; values: Array<GalleryWork> }> = [
      { size: 0, values: [] },
      { size: 0, values: [] },
      { size: 0, values: [] },
    ];
    if (cols.length > this.colNum) {
      cols.pop();
    }

    this.group.works.map((work) => {
      const target = cols.sort((a, b) => a.size < b.size ? -1 : 1)[0];
      target.size = target.size + (work.vertical ? 2 : 1.1);
      target.values.push(work);
    })
    return cols.sort((a, b) => a.size < b.size ? 1 : -1);
  }
}
