export const aboutRu = `<p><strong>Художественный конкурс «РОССИЯ — ИТАЛИЯ. 2022. ОТРАЖЕНИЕ / ITALIA — RUSSIA. 2022. RIFLESSI»</strong>  проводится некоммерческой организацией «АНИМА (Душа).
    <br/>Центр культурных инноваций», Ассоциацией школ Рима —
Scuola Maestra APS (ScuolediRoma. it) в партнерстве
с RENALIART (Национальная сеть художественных лицеев — Rete Nazionale Licei Artistici).

    <p>Конкурс проводится при поддержке Посольства Итальянской республики в Российской Федерации, Итальянского Института культуры в Москве и Посольства Российской Федерации в Итальянской республике. 

    <p>Конкурс проводится для художественных школ и лицеев, расположенных на территории городов Москва и Казань (Россия) и территории городов Рим и Милан (Италия).      

    <p><strong>Тема  конкурса — это изменения.</strong> Изменения в широком смысле этого слова — изменения личные, в жизни общества, государства, всего человечества. Приглашаем молодых художников к размышлению о том, какие новые возможности
и перспективы открывают происходящие изменения. 

    <p>На Конкурс принимаются работы воспитанников художественных школ и лицеев, отражающие тему Конкурса — живописные и графические картины на бумаге, картоне, холсте или другом материале, исполненные с помощью карандаша, красок, мелков, акварели и других средств. Нет ограничений по изобразительным решениям и технике выполнения работ.Конкурс не носит коммерческого характера и не является маркетинговой акцией. 

    <p>Участие в конкурсе — бесплатное.

    <p class="meduim">Конкурс проводится с целью расширения межкультурного обмена и взаимного познания другой культуры через искусство, популяризации современного изобразительного искусства, выявления и стимулирования талантливых художников, расширения перспектив их дальнейшего творческого роста, привлечения общественного внимания к вопросам художественного образования. 

    <p>Работы для участия в конкурсе (от 1 до 10 работ от каждой школы) загружаются на сайт в виде фотографии или скана.

    <p>Основные этапы конкурса:

    <p>Прием заявок и загрузка работ школ и лицеев — с 24 января 2022 года по 31 марта 2022 года.

    <p>Публикация работ в онлайн-галерее на сайте — с 28 февраля  2022 года по 31 марта 2022 года.

    <p class="medium">Объявление победителей Конкурса — 1 мая 2022 года.

    <p>Он-лайн выставка  работ, набравших наибольшее количество голосов, по результатам голосования жюри — 1 мая 2022 года. 
    
    <p class="medium">Победителями и получателями призов являются участники — художественные школы и лицеи городов Москва, Казань (Россия) и городов Рим и Милан (Италия). 
     
    <p>Призы и поощрительные призы предоставляются Gruppo FILA: 

    <p>2 ваучера на 2500 евро для покупки продукции Gruppo FILA, 
    <br/>2 ваучера на 1500 евро для покупки продукции Gruppo FILA, 
    <br/>2 ваучера на 1000 евро для покупки продукции Gruppo FILA, 
    <br/>4 ваучера на 500 евро для покупки продукции Gruppo FILA.
</p>`;


export const aboutIt = `<p>
  <strong>Il Concorso artistico «ITALIA — RUSSIA. 2022. RIFLESSI / РОССИЯ — ИТАЛИЯ. 2022. ОТРАЖЕНИЕ»</strong> è organizzato dall’Organizzazione senza scopo di lucro «ANIMA. Centro di innovazioni culturali», dall’Associazione di promozione sociale Scuola Maestra attraverso ScuolediRoma. it che si avvale del partenariato di RENALIART (Rete Nazionale Licei Artistici).

    <p>l concorso è promosso dall’Ambasciata Italiana nella Federazione Russa, dall’Istituto Italiano di Cultura a Mosca e dall’Ambasciata della Federazione Russa in Italia.

    <p>Il concorso è destinato alle scuole e ai licei artistici situati sul territorio delle città di Mosca e Kazan (Federazione Russa) e delle città di Roma, Città Metropolitana di Roma, di Milano, Città Metropolitana di Milano (Repubblica Italiana).
    <p><strong>Il tema del concorso è il CAMBIAMENTO,</strong> inteso nell’accezione più ampia, cambiamento individuale, cambiamento sociale, cambiamento epocale. 

    <p>I giovani artisti saranno invitati a riflettere sulle nuove opportunità che il cambiamento offre: si perde qualcosa, ma si acquistano nuove prospettive.

    <p>Le opere devono riflettere il tema del concorso e possono essere costituite da un dipinto, di un disegno grafico su carta, cartone, tela o altro materiale, realizzato a matita, colori, pastelli, acquerelli e altri materiali. 

    <p>Non ci sono restrizioni alle soluzioni figurative e alla tecnica di esecuzione delle opere. 

    <p>Il Concorso non ha scopo di lucro e non costituisce una campagna promozionale di marketing. 

    <p>La partecipazione è gratuita.
    <p><strong>Lo scopo del concorso è favorire  attraverso l'arte lo scambio tra culture diverse per una conoscenza reciproca, diffondere l'arte figurativa contemporanea, promuovere gli artisti di talento, offrire delle prospettive per la crescita professionale degli studenti, richiamare l'attenzione pubblica all'importanza dell'istruzione nel campo dell'arte</strong>. 

    <p>Le opere realizzate (da un minimo di 1 ad un massimo di 10 per ciascuna scuola) devono essere caricate in forma di foto o scansione.

    <p>Le fasi principali del concorso:

    <p>La presentazione delle domande per la partecipazione e il caricamento delle opere sul sito avverrà dal 24 gennaio 2022 al 31 marzo 2022.

    <p>Dal 28 febbraio 2022 al 31 marzo 2022 verrà realizzata l’esposizione online delle opere participanti al concorso.
    <p><strong>L’annuncio dei vincitori del concorso è previsto il 1 maggio 2022.</strong>
    <p>1 maggio 2022 esposizione della mostra on line delle opere dei laureati, che hanno ricevuto le votazioni piu alte secondo i risultati della votazione della giuria.
    <p><strong>I vincitori e riceventi dei premi sono I participant – le scuole e licei artistici delle città di Mosca e Kazan (Federazione Russa) e delle città di Roma, Città Metropolitana di Roma, di Milano, Città Metropolitana di Milano (Repubblica Italiana).</strong>
    <p>I premi e premi di incentivazione sono offerti dal Gruppo FILA: nello specifico, 2 buoni da 2.500 euro, 2 buoni da 1500 euro, 2 buoni da 1000 Euro e 4 buoni da 500 euro per l’acquisto di prodotti del Gruppo FILA.`;
