import { licenseIt } from '@/locale/license';
import { aboutIt } from '@/locale/about';
import { rulesIt } from '@/locale/rules';

export default {
  MainMenu: {
    about: 'Concorso',
    partners: 'Partner',
    rules: 'Bando',
    rulesSubmenu: {
      rules: 'Regolamento del concorso',
      license: 'Accordo di licenza',
      confidential: 'Privacy Policy',
    },
    register: 'Registrazione e caricamento',
    jury: 'Giuria',
    gallery: 'Esposizione',
    galleryOther: 'Fuori concorso',
  },
  MainPage: {
    title: `IL CONCORSO ARTISTICO «ITALIA — RUSSIA. 2022. RIFLESSI / РОССИЯ — ИТАЛИЯ. 2022. ОТРАЖЕНИЕ»`,
    reservationTitle: `DOMANDA<br>
      DI&nbsp;PARTECIPAZIONE<br/>
      E&nbsp;CARICAMENTO DELLE<br/>
      OPERE AL&nbsp;CONCORSO`,
    description: `<p>Il concorso è promosso dall’Ambasciata Italiana nella Federazione Russa, dall’Istituto Italiano di Cultura a Mosca e dall’Ambasciata della Federazione Russa in Italia.
        <p>Il concorso è destinato alle scuole e ai licei artistici situati sul territorio delle città di Mosca e Kazan (Federazione Russa) e delle città di Roma, Città Metropolitana di Roma, di Milano, Città Metropolitana di Milano (Repubblica Italiana).
        <p><strong>Il tema del concorso è il CAMBIAMENTO</strong>, inteso nell’accezione più ampia, cambiamento individuale, cambiamento sociale, cambiamento epocale. 
        <p>I giovani artisti saranno invitati a riflettere sulle nuove opportunità che il cambiamento offre: si perde qualcosa, ma si acquistano nuove prospettive.
        <p>Le opere devono riflettere il tema del concorso e possono essere costituite da un dipinto, di un disegno grafico su carta, cartone, tela o altro materiale, realizzato a matita, colori, pastelli, acquerelli e altri materiali. 
        <p>Non ci sono restrizioni alle soluzioni figurative e alla tecnica di esecuzione delle opere. <p>`,
    description2: `<p>Il Concorso non ha scopo di lucro e non costituisce una campagna promozionale di marketing. 
        <p>La partecipazione è gratuita.
        <p><strong>Lo scopo del concorso è favorire  attraverso l'arte lo scambio tra culture diverse per una conoscenza reciproca, diffondere l'arte figurativa contemporanea, promuovere gli artisti di talento, offrire delle prospettive per la crescita professionale degli studenti, richiamare l'attenzione pubblica all'importanza dell'istruzione nel campo dell'arte. </strong>
        <p>Le opere realizzate (da un minimo di 1 ad un massimo di 10 per ciascuna scuola) devono essere caricate in forma di foto o scansione.
        <p>Le fasi principali del concorso:
        <p>La presentazione delle domande per la partecipazione e il caricamento delle opere sul sito avverrà dal 24 gennaio 2022 al 31 marzo 2022.
        <p>Dal 28 febbraio 2022 al 31 marzo 2022 verrà realizzata l’esposizione online delle opere participanti al concorso.
        <p><strong>L’annuncio dei vincitori del concorso è previsto il 1 maggio 2022.</strong> 
        <p>1 maggio 2022 esposizione della mostra on line delle opere dei laureati, che hanno ricevuto le votazioni piu alte secondo i risultati della votazione della giuria.
        <p><strong>I vincitori e riceventi dei premi sono I participant – le scuole e licei artistici delle città di Mosca e Kazan (Federazione Russa) e delle città di Roma, Città Metropolitana di Roma, di Milano, Città Metropolitana di Milano (Repubblica Italiana).</strong>
        <p>I premi e premi di incentivazione sono offerti dal Gruppo FILA: 
        <br>nello specifico, 2 buoni da 2.500 euro, 2 buoni da 1500 euro, 
        <br>2 buoni da 1000 Euro e 4 buoni da 500 euro per l’acquisto 
        <br>di prodotti del Gruppo FILA.</p>`,
    slogan1: 'ITALIA — RUSSIA. 2022. RIFLESSI',
    slogan2: 'РОССИЯ — ИТАЛИЯ. 2022. ОТРАЖЕНИЕ',
    covidExtension: 'A&nbsp;CAUSA DELLA DIFFICILE CONGIUNTURA SANITARIA IL&nbsp;TERMINE DELLA PRESENTAZIONE DELLE OPERE PER IL&nbsp;CONCORSO &Egrave;&nbsp;STATO PROROGATO AL&nbsp;12&nbsp;GENNAIO 2021.',
    rankingInProgress: '&Egrave;&nbsp;IN&nbsp;CORSO LA&nbsp;VOTAZIONE DELLA GIURIA. L&rsquo;ESPOSIZIONE FINALE E&nbsp;I&nbsp;NOMI&nbsp;DEI VINCITORI SARANNO DISPONIBILI&nbsp;IL 15&nbsp;FEBBRAIO QUI.',
    resultText: `<p>Cari amici, siamo lieti di comunicarvi i risultati del concorso!
      <p>Ringraziamo tutti i partecipanti e abbiamo il piacere di presentare le 50 migliori opere selezionate dalla giuria.
      <p>I vincitori del concorso sono le tre scuole di Mosca e i tre licei di Roma con 8 opere che hanno ricevuto il maggior numero di voti.
      <p>Al Concorso hanno partecipato 423 opere provenienti da 53 scuole e licee artistici di Mosca e di Roma.
      <p>Tanti auguri ai vincitori e a tutti i partecipanti! Tanti successi per il futuro!`,
    rulesLink: 'PER INFORMAZIONI PIÙ DETTAGLIATE CLICCARE QUI',
    galleryTeaser: 'LE&nbsp;OPERE RICEVUTE PRIMA DELL&rsquo;11&nbsp;DICEMBRE 2022&nbsp;SARANNO MESSE IN&nbsp;RETE A&nbsp;PARTIRE DAL 20&nbsp;DICEMBRE 2022.',
    cancellation: 'Il concorso e rimandato <br>al anno 2023',
  },
  Footer: {
    emailFull1: 'Per i partecipanti della Repubblica Italiana: <a href="mailto:сontest2022_it@anima.moscow">сontest2022_it@anima.moscow</a>',
    emailFull2: 'Per i partecipanti della Federazione Russa: <a href="mailto:сontest2022_rus@anima.moscow">сontest2022_rus@anima.moscow</a>',
    email1: `Per i partecipanti<br/>
      della Repubblica Italiana:<br/>
      <a href="mailto:сontest2022_it@anima.moscow">сontest2022_it@anima.moscow</a>`,
    email2: `Per i partecipanti<br/>
      della Federazione Russa:<br/>
      <a href="mailto:сontest2022_rus@anima.moscow">сontest2022_rus@anima.moscow</a>`,
  },
  About: {
    text: aboutIt,
  },
  Countdown: {
    text: `Al&nbsp;termine della consegna delle opere:`,
  },
  Rules: {
    text: rulesIt,
  },
  License: {
    text: licenseIt,
  },
  Register: {
    title: 'FORMULARIO DI REGISTRAZIONE',
    form: {
      country: 'Paese',
      city: 'Città',
      school: 'Nome di scuola / liceo',
      address: 'Indirizzo di scuola / liceo',
      male: 'Sig',
      female: 'Sig.ra',
      fullName: 'Nome, cognome',
      lastName: 'Cognome',
      firstName: 'Nome',
      middleName: '',
      position: 'Carica',
      email: 'Indirizzo elettronico',
      phone: 'Numero del telefono',
      rules: 'Ho letto e sono d’accordo con le <a href="#/{language}/rules">Regole</a> e con le Condizioni <a href="#/{language}/license">dell’accordo di licenza</a>',
      preferredLang: 'Preferred language',
    },
    registerButton: 'Registrarsi',
    successRegister: `<p>Registazione compiuta.<br>
      Siamo lieti di darLe il benvenuto tra i partecipanti del Concorso artistico – «ITALIA — RUSSIA. 2022. RIFLESSI / РОССИЯ — ИТАЛИЯ. 2022. ОТРАЖЕНИЕ»</p>
        
      <p>La forma di registarzione verrà aperta succesivamente.</p>`,
    successRegisterHint: `<p>Login e Password per caricare le opere sono stati inviati al Suo indirizzo e-mial<br>
    Con utilizzo di questi login e password si può caricare tutte le opere contemporaneamente o una alla volta. Si puo caricare le opere al Concorso entro ore 23:59 del 31 marzo del 2022 (ore di Mosca).</p>

<p>Si puo caricare le opere al Concorso entro ore 23:59 del 10 dicembre 
del 2022 (ore di Mosca).</p>`,
    timeFinished: 'Consegna delle opere e terminata',
  },
  Upload: {
    title: 'CARICARE L’OPERA',
    form: {
      title: 'Titolo dell’opera {number}',
      titlePlaceholder: 'Lorem ipsum',
      description: 'Descrizione dell’opera',
      descriptionPlaceholder: 'Il commento del autore come il tema del concorso e interpretata nella opera',
      authors: 'Autori (Nome, cognome, eta’)',
      authorsPlaceholder: 'Petrotti Basilio, 10 anni; Mutti Pietro, 11 anni',
      creationPlace: 'Luogo e il periodo di realizzazione',
      material: 'Materiali',
      materialPlaceholder: 'Tela',
      width: 'Larghezza',
      height: 'Altezza',
      image: 'Immagine (Foto)',
      hint: `Attenzione: Per presentare l’opera, bisogna caricare la scansione o la fotografia nel formato jpg (dimensione dai700 ai 1000 pixel di larghezza. La dimensione del documento non deve superare 10 MB (10 megabyte).`,
      age: 'L’età',
      ageNumber: '{number} anni',
      orientationLabel: 'Orientamento del immagine',
      vertical: 'Verticale ',
      horizontal: 'Orizzontale ',
    },
    addText: 'Caricare le opere',
    uploadButton: 'Caricare',
    success: 'Le opere sono stati caricate',
    successHint: `<p>Usando il login e il password ricevuti si può caricare altre opere in più.</p>
<p>Si puo caricare le opere al Concorso entro ore 23:59 del 10 dicembre 
del 2022 (ore di Mosca).</p>`,
  },
  Jury: {
    title: 'Giuria',
    members: {
      flaminiaGiorda: {
        name: 'FLAMINIA GIORDA',
        description: 'Laureata in Lettere, specializzata in Storia dell\'Arte Medioevale e Moderna, nel 1990 ha ricevuto dall\'Accademia di San Luca il premio "H. C. Andersen" per la critica d\'arte. Docente di storia dell’arte dal 1987 al 2013; dal 2014 è Dirigente tecnico per il settore plastico-visuale presso il Ministero dell’Istruzione. Attualmente è Coordinatrice nazionale del Servizio Ispettivo e della Struttura tecnica Esami di Stato.'
      },
      marinaLoshak : {
        name: 'MARINA LOSHAK',
        description: 'Laureata in lettere presso l’Università statale di Odessa “Mechnikov”, specializzata in lettere classiche. Dal 1999 2003 è stata direttore del centro delle arti di Mosca, dal 2005 è stata direttore artistico di Gary Tatintsian Gallery. Nel 2007 ha fondato ed è diventata direttore della galleria Proun. Dal 2010 è stata membro del consiglio di esperti, e dal 2014 è diventata membro della giuria del Premio Kandinskij; nel 2017 è diventata il membro della giuria del premio “Innovatsia”. Dal 2012 è il direttore del Museo statale delle arti figurative “Pushkin”.'
      },
      teresaIarocciMavica: {
        name: 'TERESA IAROCCI MAVICA',
        description: 'Laureata in Scienze politiche presso l’Istituto Universitario Orientale di Napoli, con una specializzazione in Storia e Istituzioni politiche dell’Unione Sovietica. Nel 1989 ha proseguito gli studi a Mosca. Nel 2003 è diventata Direttrice della prima galleria d’arte contemporanea occidentale in Russia. Nel 2005, a Mosca, ha co-fondato e diretto la Contemporary City Foundation (di seguito CCF), una piattaforma sperimentale dedicata a giovani curatori e artisti russi. Nel 2009, insieme all’imprenditore Leonid Mikhelson, ha istituito a Mosca la Fondazione V–A–C. Dal 2015 al 2018, è stata membro del “Russia and Eastern Europe Acquisition Committee” (REAC) presso il museo TATE di Londra. Dal 2016 al 2020, fa parte del Supervisory Board di MANIFESTA, la biennale europea di arte contemporanea. E Direttrice V-A-C Zattere, Venezia.'
      },
      dariaPushkova: {
        name: 'DARIA PUSHKOVA',
        description: 'Rappresentante del Centro Russo di Scienza e Cultura a Roma. Giornalista professionista con 23 anni di esperienza. <br>Ha mosso i suoi primi passi nel giornalismo nel team professionale del canale NTV, dove ha lavorato come redattrice di notizie internazionali. Ha lavorato come produttrice e poi come corrispondente presso la sede di Mosca della British Broadcasting Corporation (BBC).'
      },
      danielaRizzi: {
        name: 'DANIELA RIZZI',
        description: 'Si è formata presso le università di Venezia, Roma e Mosca. Insegna letteratura e storia della cultura russa presso l’Università di Venezia Ca’ Foscari. Autrice di numerosi saggi e volumi, si è occupata di vari aspetti della storia letteraria dell\'Ottocento e del primo Novecento, sia nel campo della poesia sia in quello della prosa. Ha curato numerosi volumi della serie&nbsp;Archivio russo-italiano, ideata insieme ad A. Shishkin (Università di Salerno). <br/>Ha partecipato a oltre 60 convegni nazionali e internazionali. Da&nbsp;agosto&nbsp;2020 ricopre l’incarico di direttrice dell’Istituto italiano di cultura a Mosca.',
      },
    },
  },
  LoginForm: {
    login: 'Е-mail',
    password: 'Password',
  },
  Forgot: {
    forgotPass: 'Dimenticato la password?',
  },
  Buttons: {
    add: 'Add',
    login: 'Login',
    logout: 'Logout',
    edit: 'Edit',
    delete: 'Detele',
    save: 'Save',
    cancel: 'Cancel',
  },
  Errors: {
    imageSize: 'La dimensione del documento non deve superare 10 MB (10 megabyte).',
  },
  City: {
    ru: 'Mosca',
    it: 'Roma',
    moscow: 'Mosca',
    kazan: 'Kazan',
    roma: 'Roma',
    milano: 'Milano',
  },
  Gallery: {
    title: 'ESPOSIZIONE',
    titleWinner: 'ESPOSIZIONE',
    titleOther: 'Fuori concorso',
    textOther: 'In questa sezione vengono presentati al pubblico i disegni inviati dai bambini che stanno affrontando un percorso di cura presso il Centro medico nazionale di ricerca per l’ematologia, l’oncologia e l’immunologia infantile “Dmitri Rogacev” di Mosca. L’Ambasciata d’Italia a Mosca da molti anni sostiene le attività di ricerca e di cura di questo centro, avendo promosso nel corso degli anni diverse iniziative di beneficenza a sostegno del centro. Siamo grati ai giovani artisti per i loro disegni e ai loro insegnanti per aver supportato questa iniziativa. Buona&nbsp;visione!',
    content: 'La mostra delle opere, caricate sul sito del concorso prima del 11 dicembre. <br> L’esposizione verrà successivamente integrata dalle altre opere in arrivo',
    filter: 'Autori',
    place: 'PREMIO',
  },
  Combobox: {
    createNew: 'Crea nuovo',
    noDataAvailable: 'Nessun dato disponibile',
  },
  Partners: {
    organizators: 'ORGANIZZATORI:',
    partners: 'PARTNER:',
    sponsors: 'SPONSOR:',
  },
}
