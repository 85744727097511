

































import { Component, Prop } from 'vue-property-decorator';
import AnimaVuetifyElement from '@/components/AnimaVuetifyElement.vue';

// https://vuetifyjs.com/en/api/v-text-field/#props

@Component
export default class AnimaInput extends AnimaVuetifyElement {
  @Prop({ default: 'text' }) public type!: 'text' | 'password' | 'email';
  @Prop({ type: Boolean, default: false }) public showPassword!: boolean;
  @Prop({ type: Boolean, default: false }) public autofocus!: boolean;
  @Prop({ type: Boolean, default: false }) public counter!: boolean;
  @Prop({ type: String }) public name!: string;
  @Prop({ type: Number, default: 256 }) public maxlength!: number;
  @Prop() public appendIcon!: string;

  public showText = false;

  get localType() {
    if (this.type === 'password') {
      return this.showText ? 'text' : 'password';
    }
    return this.type;
  }
  get icon() {
    if (this.appendIcon) {
      return this.appendIcon;
    }
    if (this.showPassword) {
      return this.showText ? 'mdi-eye' : 'mdi-eye-off';
    }
    return '';
  }
}
