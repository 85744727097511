import { licenseRu } from '@/locale/license';
import { aboutRu } from '@/locale/about';
import { rulesRu } from '@/locale/rules';
import { confidentialRu } from '@/locale/confidential';

export default {
  MainMenu: {
    about: 'О конкурсе',
    partners: 'Партнеры',
    rules: 'Правила',
    rulesSubmenu: {
      rules: 'Правила конкурса',
      license: 'Лицензионное соглашение',
      confidential: 'Политика конфиденциальности',
    },
    register: 'Регистрация и загрузка',
    jury: 'Жюри',
    gallery: 'Галерея',
    galleryOther: 'Вне конкурса',
  },
  MainPage: {
    title: `ХУДОЖЕСТВЕННЫЙ КОНКУРС «РОССИЯ — ИТАЛИЯ. 2022. ОТРАЖЕНИЕ / ITALIA — RUSSIA. 2022. RIFLESSI» `,
    reservationTitle: `ПРИЕМ ЗАЯВОК<br>
      И РАБОТ НА КОНКУРС`,
    description: `<p>Конкурс проводится при поддержке Посольства Итальянской республики в Российской Федерации, Итальянского Института культуры в Москве и Посольства Российской Федерации в Итальянской республике.</p>
    <p>Конкурс проводится для художественных школ и лицеев, расположенных на территории городов Москва и Казань (Россия) и территории городов Рим и Милан (Италия). </p>    
    <p><strong>Тема  конкурса — это изменения.</strong> Изменения в широком смысле этого слова — изменения личные, в жизни общества, государства, всего человечества. Приглашаем молодых художников к размышлению о том, какие новые возможности и перспективы открывают происходящие изменения. </p>
    <p>На Конкурс принимаются работы воспитанников художественных школ и лицеев, отражающие тему Конкурса — живописные и графические картины на бумаге, картоне, холсте или другом материале, исполненные с помощью карандаша, красок, мелков, акварели и других средств. Нет ограничений по изобразительным решениям и технике выполнения работ.</p>`,
    description2: `<p>Конкурс не носит коммерческого характера и не является маркетинговой акцией. 
        <p>Участие в конкурсе — бесплатное.</p>
        <p><strong>Конкурс проводится с целью расширения межкультурного обмена и взаимного познания другой культуры через искусство, популяризации современного изобразительного искусства, выявления и стимулирования талантливых художников, расширения перспектив их дальнейшего творческого роста, привлечения общественного внимания к вопросам художественного образования.</strong></p>
        <p>Работы для участия в конкурсе (от 1 до 10 работ от каждой школы) загружаются на сайт в виде фотографии или скана.</p>
        <p>Основные этапы конкурса:
        <p>Прием заявок и загрузка работ школ и лицеев — с 24 января 2022 года по 31 марта 2022 года.
        <p>Публикация работ в онлайн-галерее на сайте — с 28 февраля  2022 года по 31 марта 2022 года.
        <p><strong>Объявление победителей Конкурса — 1 мая 2022 года.</strong>
        <p>Он-лайн выставка  работ, набравших наибольшее количество голосов, по результатам голосования жюри — 1 мая 2022 года. 
        <p><strong>Победителями и получателями призов являются участники — художественные школы и лицеи городов Москва, Казань (Россия) и городов Рим и Милан (Италия).</strong>
        <p>Призы и поощрительные призы предоставляются Gruppo FILA: 
        <p>2 ваучера на 2500 евро для покупки продукции Gruppo FILA,
        <br>2 ваучера на 1500 евро для покупки продукции Gruppo FILA, 
        <br>2 ваучера на 1000 евро для покупки продукции Gruppo FILA, 
        <br>4 ваучера на 500 евро для покупки продукции Gruppo FILA.</br>`,
    slogan1: 'РОССИЯ — ИТАЛИЯ. 2022. ОТРАЖЕНИЕ',
    slogan2: 'ITALIA — RUSSIA. 2022. RIFLESSI',
    covidExtension: 'В&nbsp;СВЯЗИ СО&nbsp;СЛОЖНОЙ САНИТАРНО- ЭПИДЕМИОЛОГИЧЕСКОЙ СИТУАЦИЕЙ ПОДАЧА РАБОТ НА&nbsp;КОНКУРС ПРОДЛЕНА ДО&nbsp;12&nbsp;ЯНВАРЯ 2021&nbsp;ГОДА.',
    rankingInProgress: 'ИДЕТ ГОЛОСОВАНИЕ ЖЮРИ. ПОСМОТРЕТЬ ИТОГОВУЮ ВЫСТАВКУ И&nbsp;УЗНАТЬ ИМЕНА ПОБЕДИТЕЛЕЙ 15&nbsp;ФЕВРАЛЯ 2021&nbsp;ГОДА МОЖНО ЗДЕСЬ',
    resultText: `<p>Мы рады объявить результаты Конкурса.</p>
  <p>Благодарим всех участников Конкурса и рады представить 50 лучших работ, отобранных жюри.
  <p>Призерами Конкурса стали 3 художественные школы Москвы и 3 художественных лицея Рима. Восемь работ учеников и студентов этих учебных заведений набрали наивысшее количество баллов.</p>
  <p>В Конкурсе участвовало: 423 работы, 53 художественные школы и лицея Москвы и Рима.</p>
  <p>Поздравляем победителей и желаем всем участникам творческих успехов!`,
    rulesLink: 'ПОДРОБНАЯ ИНФОРМАЦИЯ ЗДЕСЬ',
    galleryTeaser: 'ГАЛЕРЕЯ РАБОТ, ПОДАННЫХ НА&nbsp;КОНКУРС ДО&nbsp;11&nbsp;ДЕКАБРЯ 2022&nbsp;ГОДА БУДЕТ ДОСТУПНА ДЛЯ ПРОСМОТРА С&nbsp;20&nbsp;ДЕКАБРЯ 2022&nbsp;ГОДА.',
    cancellation: 'Конкурс перенесён <br>на 2023 год',
  },
  Footer: {
    emailFull1: 'Для участников в Российской Федерации: <a href="mailto:сontest2022_rus@anima.moscow">сontest2022_rus@anima.moscow</a>',
    emailFull2: 'Для участников в Итальянской республике: <a href="mailto:сontest2022_it@anima.moscow">сontest2022_it@anima.moscow</a>',
    email1: `Для участников<br/>
      в Российской Федерации:<br>
      <a href="mailto:сontest2022_rus@anima.moscow">сontest2022_rus@anima.moscow</a>`,
    email1short: '<a href="mailto:сontest2022_rus@anima.moscow">сontest2022_rus@anima.moscow</a>',
    email2: `Для участников<br/>
      в Итальянской республике: <br/>
      <a href="mailto:сontest2022_it@anima.moscow">сontest2022_it@anima.moscow</a>`,
    email2short: '<a href="mailto:сontest2022_it@anima.moscow">сontest2022_it@anima.moscow</a>',
  },
  About: {
    text: aboutRu,
  },
  Countdown: {
    text: `До&nbsp;окончания регистрации<br/> и&nbsp;приёма работ:`,
  },
  Rules: {
    text: rulesRu,
  },
  License: {
    text: licenseRu,
  },
  Confidential: {
    text: confidentialRu,
  },
  Register: {
    title: 'Регистрация',
    form: {
      country: 'Страна',
      city: 'Город',
      school: 'Название школы / лицея',
      address: 'Адрес школы / лицея',
      male: 'Г-н',
      female: 'Г-жа',
      fullName: 'ФИО',
      lastName: 'Фамилия',
      firstName: 'Имя',
      middleName: 'Отчество',
      position: 'Должность',
      email: 'E-mail',
      phone: 'Телефон',
      rules: 'Ознакомлен и согласен с <a href="#/{language}/rules">Правилами</a> и Условиями <a href="#/{language}/license">Лицензионного договора</a>',
      approve: 'Даю согласие на обработку своих персональных данных, получение писем и новостей.',
      preferredLang: 'Предпочтительный язык',
    },
    registerButton: 'Зарегистрироваться',
    successRegister: `<p>Регистрация пройдена успешно.
        <br>Мы рады приветствовать Вас среди участников художественного Конкурса – «РОССИЯ — ИТАЛИЯ. 2022. ОТРАЖЕНИЕ / ITALIA — RUSSIA. 2022. RIFLESSI»
      <p>Форма загрузки работ будет открыта в ближайшее время.</p>`,
    successRegisterHint: `<p>Ваши логин и пароль для загрузки работ отправлен на вашу почту.
<br>Используя данные логин и пароль, здесь Вы можете загрузить все работы одновременно или по одной по мере готовности. 
<br>Вы можете подать работы на конкурс, загрузив их до 23:59 (время московское) 31 марта 2022 года.</br>`,
    timeFinished: 'Подача работ завершена',
  },
  Upload: {
    title: 'ЗАГРУЗКА РАБОТ',
    form: {
      title: 'Название работы {number}',
      titlePlaceholder: 'Утро в парке',
      description: 'Описание работы',
      descriptionPlaceholder: 'Комментарий автора о том, как тема конкурса раскрывается в данной работе',
      authors: 'Авторы (имя, фамилия, возраст)',
      authorsPlaceholder: 'Петров Вася, 10 лет; Васильев Петя, 11 лет',
      creationPlace: 'Место и время создания',
      material: 'Материалы',
      materialPlaceholder: 'холст',
      width: 'Ширина',
      height: 'Высота',
      image: 'Прикрепленные файлы',
      hint: `Работы предоставляются в виде скана или фотографии в формате jpg (размер от 700 до 1000 пикселей по ширине). Размер файла не более 10 MB (10 мегабайт). Каждая школа загружает от 1 до 10 работ.`,
      age: 'Возраст',
      ageNumber: '{number} лет',
      orientationLabel: 'Ориентация изображения',
      vertical: 'Вертикальная',
      horizontal: 'Горизонтальная',
    },
    addText: 'Добавить работу',
    editText: 'Редактировать работу',
    uploadButton: 'Загрузить',
    success: 'Ваши работы загружены',
    successHint: `<p>Используя полученные Вами ранее логин и пароль,
Вы можете дополнительно загрузить работы по мере готовности.</p>

<p>Вы можете подать работы на конкурс, загрузив их до 23:59 
(время московское) 12 января 2021 года.</p>`,
  },
  Jury: {
    title: 'Жюри',
    members: {
      flaminiaGiorda: {
        name: 'ФЛАМИНИЯ ДЖОРДА',
        description: `<p>Филолог по образованию, специализация — история средневекового и современного искусства. В 1990 г. получила премию «Г.Х.Андерсена» от Академии Сан Лука за заслуги в области искусствоведения. С 1987 по 2013 гг. преподавала историю искусства. С 2014 г. — технический директор департамента пластических и визуальных искусств Министерства образования Италии. В настоящее время — национальный координатор инспектората и технической структуры государственных экзаменов.</p>`
      },
      marinaLoshak : {
        name: 'МАРИНА ДЕВОВНА ЛОШАК',
        description: `
<p>Окончила Одесский государственный университет имени И.И. Мечникова по специальности «Классическая филология». С 1999 по 2003 — директор Московского центра искусств (МЦИ) на Неглинной, с 2005 — арт-директор Галереи Гари Татинцяна (Gary Tatintsian Gallery). В 2007 г. основала и возглавила галерею «Проун». С 2010 года — член экспертного совета, а с 2014 — в составе жюри Премии Кандинского; в 2017 году вошла в жюри премии «Инновация». С 2012 г. — руководитель Музейно-выставочного объединения «Манеж». С 2013 г. — Директор Государственного музея изобразительных искусств имени А.С. Пушкина.</p>`
      },
      teresaIarocciMavica: {
        name: 'ТЕРЕЗА ЯРОЧЧИ МАВИКА ',
        description: `<p>Окончила факультет политических наук Университета Востока&nbsp;в&nbsp;Неаполе, где её специализацией была история 
и политические институты Советского Союза, в 1989 году продолжила обучение в Москве. В 2005 г. стала руководителем московского фонда «Современный город»&nbsp;— свободной экспериментальной площадки, которая содействовала молодым кураторам и художникам в профессиональном становлении. В 2009 году совместно с&nbsp;Леонидом Михельсоном&nbsp;она основала&nbsp;фонд V-A-C&nbsp;
и стала его директором. С 2015 по 2018 год была членом Комитета по приобретениям в России и Восточной Европе (Russian and Eastern European Acquisition Committee, REEAC)&nbsp;галереи Тейт, а с 2016 года она является участником Экспертного совета Европейской биеннале современного искусства «Манифеста». Директор V-A-C Дзаттере, Венеция.</p>`
      },
      dariaPushkova: {
        name: 'ДАРИЯ АЛЕКСЕЕВНА ПУШКОВА',
        description: `<p>Руководитель Российского центра науки и культуры в Риме. Профессиональный журналист с 23-летним стажем.</p>
<p>Первые шаги в журналистике сделала в коллективе телеканала НТВ, где работала редактором международных новостей. Работала продюсером, а затем корреспондентом в Московском представительстве Британской телерадиовещательной корпорации (Би-Би-Си). </p>`
      },
      danielaRizzi: {
        name: 'ДАНИЕЛА РИЦЦИ',
        description: `<p>Проходила обучение&nbsp;в университетах Венеции, Рима и Москвы.&nbsp;Преподает литературу и историю русской культуры в&nbsp;венецианском университете Ка-Фоскари.&nbsp;Автор многочисленных очерков и книг. Изучала различные аспекты истории литературы девятнадцатого и начала двадцатого веков, как в области поэзии, так и прозы. Редактор&nbsp;&nbsp;многочисленных&nbsp;томов&nbsp;серии&nbsp;«Русско-итальянский архив», в создании которой принимал участие А. Шишкин (профессор Салернского Университета).&nbsp;Участвовала в более чем 60 национальных и международных конференциях.</p>
<p>С августа 2020 года занимает должность директора Итальянского института культуры в Москве.</p>`,
      },
    },
  },
  LoginForm: {
    login: 'Е-mail',
    password: 'Пароль',
  },
  Forgot: {
    forgotPass: 'Забыли пароль?',
  },
  Buttons: {
    add: 'Добавить',
    login: 'Войти',
    logout: 'Выйти',
    edit: 'Редактировать',
    delete: 'Удалить',
    save: 'Сохранить',
    cancel: 'Отмена',
  },
  Errors: {
    required: 'Поле обязательно к заполнению',
    unexpected: 'Непредвиденная ошибка',
    invalidEmail: 'Некорректный e-mail',
    invalidPhone: 'Некорректный телефон',
    invalidCredentials: 'Неверный логин или пароль',
    duplicatedEmail: 'Участник с таким e-mail уже есть',
    duplicatedPhone: 'Участник с таким телефоном уже есть',
    imageSize: 'Размер файла более 10 MB (10 мегабайт).',
  },
  City: {
    ru: 'г. Москва',
    it: 'г. Рим',
    moscow: 'Москва',
    kazan: 'Казань',
    roma: 'Рим',
    milano: 'Милан',
  },
  Gallery: {
    title: 'ГАЛЕРЕЯ',
    titleWinner: 'ВЫСТАВКА',
    titleOther: 'ВНЕ КОНКУРСА',
    textOther: 'В этом разделе представлены рисунки детей, которые проходят лечение в Национальном медицинском исследовательском центре детской гематологии, онкологии и иммунологии имени Дмитрия Рогачева (НМИЦ ДГОИ им. Дмитрия Рогачева) в Москве. Посольство Италии в Москве уже на протяжении многих лет поддерживает центр, организовывая разнообразные благотворительные мероприятия в его поддержку. Мы благодарны молодым художникам за их рисунки, а их преподавателям - за поддержку нашей инициативы. Приятного просмотра!',
    content: 'Галерея работ, поданных<br> на конкурс до 11 декабря 2022 года. <br> Галерея будет дополнена.',
    filter: 'Авторы',
    place: 'МЕСТО',
  },
  Combobox: {
    createNew: 'Добавить новый',
    noDataAvailable: 'Нет данных',
  },
  Partners: {
    organizators: 'ОРГАНИЗАТОРЫ:',
    partners: 'ПАРТНЕРЫ:',
    sponsors: 'СПОНСОРЫ:',
  },
}
