


















































import { Component, Vue } from 'vue-property-decorator';
import Countdown from "@/components/Countdown.vue";
import RouterNames from "@/router/RouterNames";
import Gallery from '@/components/Gallery.vue';

@Component({
  components: {
    Countdown,
    Gallery,
  },
})
export default class MainPage extends Vue {
  public RouterNames = RouterNames;

  get language() {
    return this.$route.params.language || 'ru';
  }

  get isGalleryPeriod() {
    return this.$store.getters.isGalleryPeriod || this.$route.query.forcegallery;
  }

  get isRegisterPeriod() {
    return this.$store.getters.isRegisterPeriod;
  }

  get isResultPeriod() {
    return this.$store.getters.isResultPeriod;
  }

  get isFullyLocked() {
    return this.$store.getters.isFullyLocked;
  }
}
