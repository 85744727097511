




















































import { Component, Emit, Vue } from 'vue-property-decorator';
import FormValidator from '@/services/FormValidator';
import AnimaButton from '@/components/AnimaButton.vue';
import RouterNames from '@/router/RouterNames';
import { RESPONSE } from '@/constants';
import { UserParams } from '@/models/User';

@Component({
  components: {
    AnimaButton,
  },
})
export default class LoginForm extends Vue {
  public RouterNames = RouterNames;
  public form = {}
  public valid = true;

  get hint(): boolean {
    return this.$store.getters.enableLoginHint;
  }

  get user(): UserParams {
    return this.$store.getters.user;
  }

  get language() {
    return this.$route.params.language || 'ru';
  }

  get vform(): HTMLFormElement {
    return this.$refs.loginForm as HTMLFormElement;
  }

  get isUploadEnabled() {
    return this.$store.getters.isUploadEnabled;
  }

  get rules() {
    return {
      password: [FormValidator.required],
      login: [
        FormValidator.required,
        FormValidator.email,
      ],
    };
  }

  @Emit()
  public success() {
    return
  }

  public action() {
    if (this.vform.validate()) {
      this.$store.dispatch('postLogin', this.form)

      // 1001 - поле не заполнено (это может не понадобиться тебе, я думаю у тебя и так своя валидация будет на фронте)
      // 1002 - неверный E-mail (не прошёл маску)
      // 1005 - неверная пара логин-пароль
        .catch((error) => {
          if (error.code === RESPONSE.InvalidEmail) {
            this.$notify({
              type: 'error',
              title: String(this.$t('Errors.invalidEmail')),
              message: '',
              duration: 0,
            });
            return;
          }
          if (error.code === RESPONSE.IncorrectCredentials) {
            this.$notify({
              type: 'error',
              title: String(this.$t('Errors.incorrectCredentials')),
              message: '',
              duration: 0,
            });
            return;
          }
          this.$notify({
            type: 'error',
            title: String(this.$t('Errors.unexpected')),
            message: '',
            duration: 0,
          });
        });
    }
  }

  public logout() {
    this.$store.dispatch('logout');
  }
}
