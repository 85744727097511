





import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class MyRouterLink extends Vue {
  @Prop({ required: true }) public to!: object; // router-link params
  @Prop({ type: Boolean, default: false }) public disabled!: boolean;
}
