import { ActionContext, Module } from 'vuex';
import { UserApi } from '@/api';
import { LoginCredentials, RegisterCredentials } from '@/api/user.api';
import { UserParams } from '@/models/User';

interface AuthStorage {
  user: UserParams;
  enableLoginHint: boolean;
  token: string;
}

const sessionUser = sessionStorage.getItem('user');
const sessionToken = sessionStorage.getItem('token');

export default {
  state: {
    user: sessionUser ? JSON.parse(sessionUser) : {},
    token: sessionToken ? JSON.parse(sessionToken) : '',
    enableLoginHint: false,
  } as AuthStorage,
  getters: {
    user(state: AuthStorage): UserParams {
      return state.user;
    },
    token(state: AuthStorage): string {
      return state.token;
    },
    enableLoginHint(state: AuthStorage): boolean {
      return state.enableLoginHint;
    },
    authorized(state: AuthStorage): boolean {
      return Boolean(state.user.email);
    },
  },
  mutations: {
    setUserData(state: AuthStorage, data: any) {
      state.user = data;
      sessionStorage.setItem('user', JSON.stringify(data));
    },
    setLoginHint(state: AuthStorage, data: boolean) {
      state.enableLoginHint = data;
    },
    setToken(state: AuthStorage, data: string) {
      state.token = data;
      sessionStorage.setItem('token', JSON.stringify(data));
    },
    resetUserData(state: AuthStorage) {
      state.user = {} as UserParams;
      sessionStorage.clear();
    }
  },
  actions: {
    async postRegister({ state, commit, dispatch, getters }: ActionContext<AuthStorage, any>, params: RegisterCredentials) {
      return UserApi.postRegister(params).then(() => {
        commit('setLoginHint', true);
      })
    },
    async postLogin({ state, commit, dispatch, getters }: ActionContext<AuthStorage, any>, params: LoginCredentials) {
      return UserApi.postLogin(params).then((response) => {
        commit('setUserData', response.data.fields);
        commit('setLoginHint', false);
      });
    },
    logout({ commit }: ActionContext<AuthStorage, any>) {
      commit('resetUserData');
    }
  },
} as Module<AuthStorage, any>;
