






































import { Component, Vue } from 'vue-property-decorator';
import RouterNames from "@/router/RouterNames";

@Component
export default class LogoMain extends Vue {
  public RouterNames = RouterNames;

  get language() {
    return this.$route.params.language || 'ru';
  }

  public goToMain() {
    this.$store.commit('setIsMenuOpen');
    if (this.$route.name !== RouterNames.StartPage) {
      this.$router.push({ name: RouterNames.StartPage, params: { language: this.language } });
    }
  }
}
