
















































































































import { Component, Emit, Vue } from 'vue-property-decorator';
import FormValidator from '@/services/FormValidator';
import AnimaButton from '@/components/AnimaButton.vue';
import { RESPONSE } from '@/constants';
import VueRecaptcha from 'vue-recaptcha';
import { HumanName, RegisterCredentials, RegisterCredentialsBase } from '@/api/user.api';
import VueI18n from "vue-i18n";
import TranslateResult = VueI18n.TranslateResult;
import AnimaCombobox from "@/components/AnimaCombobox.vue";

interface AbstractCity {
  value: string;
  text: TranslateResult;
}
interface CityItem extends AbstractCity{
  location: string;
}
interface Form extends RegisterCredentialsBase {
  city: AbstractCity;
}

@Component({
  components: {
    AnimaButton,
    AnimaCombobox,
    VueRecaptcha,
  },
})
export default class RegisterForm extends Vue {
  public form = {} as Form;
  public user = {} as HumanName;
  public valid = true;
  public rulesApprove = false;
  public siteKey = '6Le8X-EZAAAAAL-hevRbVELi0NLR-XnAtWFlXRFk';

  get countries() {
    return [
      { text: 'Россия', value: 'ru' },
      { text: 'Italia', value: 'it' },
    ].sort((a) => {
      return a.value === this.language ? -1 : 0;
    });
  }


  get cities(): CityItem[] {
    const list = [
      { value: 'moscow', location: 'ru' },
      { value: 'kazan', location: 'ru' },
      { value: 'roma', location: 'it' },
      { value: 'milano', location: 'it' },
    ];
    const reduceByLocation = (acc: { [key: string]: CityItem[] }, next: CityItem) => {
      if (!acc[next.location]) {
        acc[next.location] = [next];
      } else {
        acc[next.location].push(next);
      }
      return acc;
    };
    const addText = (item: { value: string; location: string }) => {
      const text = this.$t(`City.${item.value}`);
      return { ...item, text };
    };
    const reduced = list.map(addText).reduce(reduceByLocation, {});
    const keys = Object.keys(reduced).sort((key) => {
      return key === this.language ? -1 : 0;
    });
    return keys.map((key) => reduced[key]).flat();
  }

  get languages() {
    return [
      { text: 'Русский', value: 'ru' },
      { text: 'Italiana', value: 'it' },
    ].sort((a) => {
      return a.value === this.language ? -1 : 0;
    })
  }

  get language() {
    return this.$route.params.language || 'ru';
  }

  get vform(): HTMLFormElement {
    return this.$refs.registerForm as HTMLFormElement;
  }

  get recaptcha(): VueRecaptcha {
    return this.$refs.recaptcha as VueRecaptcha;
  }

  get rules() {
    return {
      country: [FormValidator.required],
      city: [FormValidator.required],
      school: [FormValidator.required],
      address: [FormValidator.required],
      gender: [FormValidator.required],
      fullName: [FormValidator.required],
      position: [FormValidator.required],
      email: [
        FormValidator.required,
        FormValidator.email,
      ],
      phone: [
        FormValidator.required,
        FormValidator.phone,
      ],
      rulesApprove: [FormValidator.required],
      emailApprove: [FormValidator.required],
    };
  }

  @Emit()
  public success() {
    return
  }

  public validate() {
    if (this.vform.validate()) {
      this.recaptcha.execute();
    }
  }

  public action() {
    if (this.vform.validate()) {
      this.form.fullName = `${this.user.lastName} ${this.user.firstName} ${this.user.middleName || ''}`.trim();
      this.$store.dispatch('postRegister', {
        ...this.form,
        city: this.form.city.value,
      }).then(() => {
        this.success();
      }).catch((error) => {
        if (error.code === RESPONSE.InvalidEmail) {
          this.$notify({
            type: 'error',
            title: String(this.$t('Errors.invalidEmail')),
            message: '',
            duration: 0,
          });
          return;
        }
        if (error.code === RESPONSE.DuplicatedEmail) {
          this.$notify({
            type: 'error',
            title: String(this.$t('Errors.duplicatedEmail')),
            message: '',
            duration: 0,
          });
          return;
        }
        if (error.code === RESPONSE.DuplicatedPhone) {
          this.$notify({
            type: 'error',
            title: String(this.$t('Errors.duplicatedPhone')),
            message: '',
            duration: 0,
          });
          return;
        }
        this.$notify({
          type: 'error',
          title: String(this.$t('Errors.unexpected')),
          message: '',
          duration: 0,
        });
      });
    }
  }

  public onCaptchaExpired() {
    this.recaptcha.reset();
  }

  public created() {
    this.form.language = this.language;
  }
}
