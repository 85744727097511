










import { Component, Vue } from 'vue-property-decorator';
import AnimaButton from '@/components/AnimaButton.vue';
import VueRecaptcha from 'vue-recaptcha';
import AnimaInput from '@/components/AnimaInput.vue';
import RegisteredWork from '@/models/RegisteredWork';
import WorkPreview from '@/components/WorkPreview.vue';

@Component({
  components: {
    AnimaButton,
    VueRecaptcha,
    AnimaInput,
    WorkPreview,
  },
})
export default class RegisteredWorks extends Vue {
  get works(): RegisteredWork[] {
    return this.$store.getters.works;
  }

  public created() {
    this.$store.dispatch('downloadWorks');
  }
}
