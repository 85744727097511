






















































import { Component, Prop, Vue } from 'vue-property-decorator';
import RouterNames from "@/router/RouterNames";
import RegisterAndUpload from '@/views/RegisterAndUpload.vue';
import MyRouterLink from "@/components/MyRouterLink.vue";

@Component({
  components: {
    RegisterAndUpload,
    MyRouterLink,
  },
})
export default class MainMenu extends Vue {
  @Prop({ type: Boolean, default: false }) public vertical!: boolean;

  public RouterNames = RouterNames;

  public showRegisterForm = false;

  get language() {
    return this.$route.params.language || 'ru';
  }
  get isResultPeriod() {
    return this.$store.getters.isResultPeriod;
  }

  public openRegisterForm() {
    this.showRegisterForm = true;
  }

  get mobile() {
    return screen.width < 1140;
  }
}
