import Vue from 'vue'
import VueRouter, { Route, RouteConfig } from 'vue-router';
import MainPage from "@/views/MainPage.vue";
import RouterNames from "@/router/RouterNames";
import Storage from '@/store';
import RouterPage from '@/views/RouterPage.vue';
import { i18n } from '@/services/i18n';

Vue.use(VueRouter)

const allLanguages = ['ru', 'it'];
export function isIncorrectLang(language: string) {
  return !allLanguages.includes(language);
}

const routes: Array<RouteConfig> = [
  {
    path: '/:language?',
    component: RouterPage,
    children: [
      {
        path: '',
        name: RouterNames.StartPage,
        component: MainPage,
      },
      {
        path: 'partners',
        name: RouterNames.Partners,
        component: () => import(/* webpackChunkName: "Partners" */ '../views/Partners.vue')
      },
      {
        path: 'rules',
        name: RouterNames.Rules,
        component: () => import(/* webpackChunkName: "Rules" */ '../views/Rules.vue')
      },
      {
        path: 'license',
        name: RouterNames.License,
        component: () => import(/* webpackChunkName: "License" */ '../views/License.vue')
      },
      {
        path: 'about',
        name: RouterNames.About,
        component: () => import(/* webpackChunkName: "About" */ '../views/About.vue')
      },
      {
        path: 'confidential',
        name: RouterNames.Confidential,
        component: () => import(/* webpackChunkName: "About" */ '../views/Confidential.vue')
      },
      {
        path: 'jury',
        name: RouterNames.Jury,
        component: () => import(/* webpackChunkName: "Jury" */ '../views/Jury.vue')
      },
      {
        path: 'register',
        name: RouterNames.Register,
        component: () => import(/* webpackChunkName: "Register" */ '../views/RegisterAndUpload.vue')
      },
      {
        path: 'gallery',
        name: RouterNames.Gallery,
        component: () => import(/* webpackChunkName: "FullGallery" */ '../views/FullGallery.vue')
      },
      {
        path: 'rogachev',
        name: RouterNames.OtherGallery,
        component: () => import(/* webpackChunkName: "FullGallery" */ '../views/OtherGallery.vue')
      },
      {
        path: 'contest2020',
        name: RouterNames.Contest2020,
        beforeEnter() { location.href = 'http://contest2020.anima.moscow' }
      },
    ]
  },
]

const router = new VueRouter({
  routes
})

router.beforeEach((to: Route, from: Route, next: any) => {
  Storage.commit('setIsMenuOpen');
  const language = isIncorrectLang(to.params.language) ? 'ru' : to.params.language;
  i18n.locale = language;
  next();
});

export default router
