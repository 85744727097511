export interface GalleryWorkParams {
  title: string;
  description: string;
  description2: string;
  authors: string;
  created: string;
  creationPlace?: string; // deprecated
  language: string;
  school: string;
  img: string;
  thumb370: string;
  thumb740: string;
  id: number;
  user_id: number;
  material?: string;
  vertical?: boolean;
  winner: number;
}

export default class GalleryWork {
  public title: string;
  public description: string;
  public description2: string;
  public authors: string;
  public created: string;
  public creationPlace?: string; // deprecated
  public language: string;
  public school: string;
  public img: string; // URL
  public thumb370: string; // URL
  public thumb740: string; // URL
  public id: number;
  public userId: number;
  public material?: string;
  public vertical: boolean;
  public winner: number;

  constructor(props: GalleryWorkParams) {
    this.title = props.title;
    this.description = props.description;
    this.description2 = props.description2;
    this.authors = props.authors;
    this.created = props.created;
    this.creationPlace = props.creationPlace;
    this.language = props.language;
    this.school = props.school;
    this.img = props.img;
    this.thumb370 = props.thumb370;
    this.thumb740 = props.thumb740;
    this.id = props.id;
    this.userId = props.user_id;
    this.material = props.material;
    this.winner = props.winner;
    this.vertical = Boolean(props.vertical);
  }
}
