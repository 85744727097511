








import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component
export default class AnimaButton extends Vue {
  @Prop({ type: Boolean, default: false }) public disabled!: boolean;

  @Emit()
  public click() {
    return;
  }
}
