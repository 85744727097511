import { i18n } from '@/services/i18n';

export default class FormValidator {
  public static required(value: any) {
    return Boolean(value) || i18n.t('Errors.required');
  }
  public static email(value: string) {
    const mailFormat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return Boolean((value || '').match(mailFormat)) || i18n.t('Errors.invalidEmail');
  }
  public static phone(value: string) {
    const phoneFormat = /[\d() \-+]{4,14}\d$/;
    return Boolean((value || '').match(phoneFormat)) || i18n.t('Errors.invalidPhone');
  }
}
