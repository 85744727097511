import Vue from 'vue';
import VueI18n from 'vue-i18n';
import ruLang from '@/locale/ru';
import itLang from '@/locale/it';
import _ from 'lodash';

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: 'ru',
  messages: {
    ru: ruLang,
    it: _.merge({}, ruLang, itLang),
  },
});
